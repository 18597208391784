import React, { useState } from 'react';
import '../../styles/shared/form.scss';

export default () => {
    return (
        <div className="my-5 py-5">
            <h3 className="lead mb-4">Reset Password</h3>
            <form action="" className="shared_form">
                <input type="email" placeholder="Email Address" name="email" id="email" className="shared_input"/>
                <button type="submit">Submit</button>
            </form>
        </div>
    )
}
