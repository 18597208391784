export default function(string) {
    string = removeClothingTerms(string);
    
    let sArray = string.split(' ');
    let result = [];
    let count = 0;

    var n = sArray.length;
    for (let i = 0; i < n; i++) {
      for (let j = 0; j <= i; j++) {
        result.push(sArray.slice(j, n - i + j).join(' '));
        count++
      }
    }
    
    return count;
    // return result;

}

function removeClothingTerms(string) {
    if (typeof string !== 'string') throw new Error('argument must be of type string')  

    return string.replace(/ shirt | tshirt | t-shirt | sweater | tee | t /ig, '').trim();
}
