import React, { Component } from "react";

import { results_class, resultsStat, resultsContainer, possibleCombItem, possibleCombContainer,
    possibleCombItemIgnored, possibleCombTitle, rows_title, resultsReveal, revealButton } from "../../styles/main/results.module.scss";
import MarkRow from "../common/markRow";
import { codesStatusMap } from "@merchprotect/trademark-status-codes";
import NoResults from "./noResults";

export default class Results extends Component {
    state = {
        openCard: false,
        openCombinationStats: false,
        resultsToShow: 10,
    };

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.state.openCard !== nextState.openCard) return true;
    //     if (this.state.openCombinationStats !== nextState.openCombinationStats) return true;
    //     if(this.state.resultsToShow !== nextState.resultsToShow) return true;

    //     if (JSON.stringify(this.props.results) === JSON.stringify(nextProps.results)) {
    //         return false;
    //     }
    //     return true;
    // }

    handleCardClick = () => {
        if (window.innerWidth < 450) {
            this.setState({ openCard: !this.state.openCard });
        }
    };

    handleCombinationsClick = () => {
        this.setState({ openCombinationStats: !this.state.openCombinationStats })
    }

    handleRevealClick = () => {
        this.setState({
            resultsToShow: this.state.resultsToShow + 10
        })
    }

    shouldShowTitle() {
        let {results, secondaryList} = this.props; 

        if (results === null) {
            return false;
        } else if (!results.length && secondaryList) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        let { openCombinationStats, resultsToShow } = this.state;
        let { results, allPossibleAdjacentWordCombs = [], ignoredCommonWords = [], searchPhrase, showStats, 
            rowsTitle, style, titleStyle } = this.props;
        let showRevealButton = false;
        if(!allPossibleAdjacentWordCombs) allPossibleAdjacentWordCombs = [];
        if(!ignoredCommonWords) ignoredCommonWords = [];
        if(this.props.results && this.props.results.length > resultsToShow) showRevealButton = true;
        
        return (
            <div className={resultsContainer}>
                {showStats && <p className={resultsStat}>
                    Processed {" "}
                    <span onClick={this.handleCombinationsClick} style={{ fontSize: "18px", color: "#606060fc", cursor: 'pointer', textDecoration: 'underline' }}>
                        {true ? allPossibleAdjacentWordCombs.length + ignoredCommonWords.length : '1'} 
                    <span> keyword combinations</span>
                    </span>
                    , found
                    <span style={{ fontWeight: "bold", fontSize: "18px", color: "#606060fc" }}>
                        {" "}
                        {this.props.results ? this.props.results.length : 0}{" "}
                    </span>
                    Trademark(s)
                </p>}
                {showStats && openCombinationStats && <div className={possibleCombContainer}>
                    <div className="mb-3">
                        <h3 className={possibleCombTitle + ''}>Ignored</h3>
                        {ignoredCommonWords.length !== 0 ? ignoredCommonWords.map(ignoredComb => <span className={possibleCombItemIgnored}>{ignoredComb}</span>) 
                        : <p className="lead">No Ignored Keywords</p>
                        }
                    </div>
                    <div className="mb-3">
                        <h3 className={possibleCombTitle}>Looked up</h3>
                        {true ? allPossibleAdjacentWordCombs.sort((a, b) => a.length - b.length).map(possibleComb => <span key={possibleComb} className={possibleCombItem}>{possibleComb}</span>)
                            : <span className={possibleCombItem}>{searchPhrase}</span> }
                    </div>
                </div>}
                {this.shouldShowTitle() && <div className="pt-4"><p className={rows_title} style={titleStyle}><i className={this.props.titleIconClass}></i> {"  "}{rowsTitle} <span style={{color: '#00000065', fontSize: 16, verticalAlign: 'top'}}>({results.length})</span></p></div>}
                {results && results.length ?  <>
                        <div className={results_class + ' ' + resultsReveal} >
                            {/* <p className={`lead ${rowsCount}`}>{results.length} Results</p> */}
                            {results.slice(0, resultsToShow).map(mark => {
                                let mappedMark = {
                                    ...mark,
                                    mark_id_char: mark.markWord,
                                    cfh_status_cd: mark.statusCode,
                                    registration_dt: mark.registrationDate,
                                    filing_dt: mark.filingDate,
                                    stat_text: mark.statements,
                                    mark_draw_cd: mark.markCode,
                                    party_name: mark.partyName,
                                    transaction_dt: mark.transactionDate,
                                    intlClasses: mark.intlClasses ? mark.intlClasses.map(iclass => ({code: iclass})) : [],
                                    usClasses: mark.usClasses ? mark.usClasses.map(iclass => ({code: iclass})) : [],
                                }
                                return (
                                    // <MarkCard
                                    //     mark={mark}
                                    //     key={mark.serial_no}
                                    //     handleCardClick={this.handleCardClick}
                                    //     openCard={openCard}
                                    // />
                                    <MarkRow style={style}
                                        mark={mappedMark}
                                        hideSimilarMerch={true}
                                        key={mark.serial_no}
                                        largeCards={true}
                                        defaultToOpen={results.length === 1} 
                                    />
                                );
                            })}
                            {showRevealButton && <div onClick={this.handleRevealClick} className={revealButton}><i className="fas fa-chevron-down"></i></div>}
                        </div>
                    </>
                :
                 showStats ? <NoResults /> : null
                }
            </div>
        );

    }
}

// TODO sorting is rearranging marks differently everytime;

