import React, { Component } from "react";
import SearchBox from "./searchBox";
import Details from "./details";
import ErrorBoundary from "../../sharedComponents/errorBoundary/error";
import Footer from "../../sharedComponents/footer";

export default () => (
    <>
        <ErrorBoundary>
            <SearchBox />
        </ErrorBoundary>
        <Details />
    </>
);
