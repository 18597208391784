import React, { Component } from "react";
import {connect} from "react-redux";
import axios from "axios";
import Error from "../../sharedComponents/error";
import Plan from "./plan.view";
import Spinner from "../../sharedComponents/spinner";

import { container, plansWrapper, pageTitle, note, dealWrapper } from "../../styles/plans/plans.module.scss";
import ErrorBoundary from "../../sharedComponents/errorBoundary/error";
import { layout_limited } from '../../styles/layout.module.scss';

class Plans extends Component {
    state = {
        plans: [],
        error: false
    };

    async componentDidMount() {
        try {
            const {
                data: { plans }
            } = await axios.get(
                process.env.NODE_ENV !== "production"
                    ? "plans"
                    : "https://api.merchprotect.com/plans"
            );
            plans.sort((a, b) => {
                let aPrice = a.price;
                let bPrice = b.price;
                if (aPrice === null) aPrice = 100000; 
                if (bPrice === null) bPrice = 100000; 
                return aPrice - bPrice
            });
            this.setState({
                plans
            });
        } catch (err) {
            this.setState({
                error: err.message
            });
        }
    }

    render() {
        const { plans, error } = this.state;
        const { userPlan, signedin } = this.props;

        if (error) {
            return <Error message={error} />;
        }

        return (
            <div className={layout_limited}>
                <ErrorBoundary>
                    <div className={container}>
                        <p className={pageTitle}>PLANS</p>
                        <div className={plansWrapper}>
                            <div className="p-3 px-4 w-100 mx-auto shadow-sm mb-5" style={{ maxWidth: 675, backgroundColor: '#f4f2a4' }}>
                                <p className="lead" style={{ color: '#9a6400', fontSize: 16, fontWeight: 600}}>
                                    NOTE: Paid subscriptions will be available very soon. Currently you can subscribe to our free plan and you will be notified when we launch our Beta version.  
                                </p>
                            </div>
                            {/* <div className={dealWrapper + ' mb-5'}>
                                <h3><i className="fas fa-rocket"></i> LIMITED LAUNCH DEAL</h3>
                                <p>Signup today and get and extended trial period (see below).</p>
                                <p>Satisfaction Guaranteed</p>
                            </div> */}
                            {/* <p className={note}>
                                <b style={{ fontWeight: "bold" }}>NOTE:</b> It is worth noting that your
                                merch limit is per each merch product and not per each Keyword. For instance, if
                                your merch product contains 25 words, we will be tracking around 300 keywords but
                                it will only count as one Merch on your limit.
                            </p> */}
                            <div className="d-flex flex-wrap justify-content-center" style={{maxWidth: 1000}}>
                                {!plans.length ? (
                                    <Spinner data-testid="spinner" />
                                ) : (
                                    plans.map(plan => {
                                        return <Plan key={plan.plan_id} plan={plan} userPlan={userPlan} signedin={signedin}/>;
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>
            </div>
        );
    }
}

const mapState = state => {
    return {
        userPlan: state.auth.plan,
        signedin: state.auth.signedin,
    }
}

export default connect(mapState, null)(Plans)