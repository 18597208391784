import React, { useState } from 'react';

import { paragraph, subTitle } from '../../styles/info/info.module.scss';

export default ({ title, children }) => {
    let [open, setOpen] = useState(false);

    return (
        <div className={paragraph}>
                <span className={subTitle} onClick={() => setOpen(!open)}>
                    {!open && <i className="fas fa-caret-right"></i>} 
                    {open && <i className="fas fa-caret-down" style={{transform: 'translateX(-40%)'}}></i>} 
                {" "}{title}</span>
                <div style={{maxHeight: open ? 2000 : 0, overflow: 'hidden', transition: 'all 0.3s linear'}}>
                    {children}
                </div>
        </div>
    )
}