import React from "react";
import { Link } from "react-router-dom";
import Spinner from "../../sharedComponents/spinner";

import {
    container,
    wrapper,
    title,
    form,
    input,
    text,
    button,
    switchPhrase,
    switchP,
    errorWrapper,
    errorMessage
} from "../../styles/login.module.scss";

export default ({
    email,
    password,
    handleInputChange,
    handleSubmit,
    handleSwitch,
    loginError,
    loggingIn
}) => (
    <div className={container}>
        <div className={wrapper}>
            <p className={title}>LOGIN</p>
            {loginError ? (
                <div className={errorWrapper}>
                    <p className={`${errorMessage}`}>{loginError}</p>
                </div>
            ) : null}
            {loggingIn && <Spinner line={true} />}
            <form className={form} id="signin" onChange={handleInputChange} onSubmit={handleSubmit}>
                <input
                    className={`${input} ${text}`}
                    placeholder="email.."
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                />
                <input
                    className={`${input} ${text}`}
                    placeholder="password.."
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                />
                <input className={`${input} ${button}`} type="submit" value="login" />
            </form>
            {/* <Link to="/reset-password">Reset Password</Link> */}
        </div>
        <p className={switchP}>
            Do not have an account?{" "}
            <span className={switchPhrase} onClick={handleSwitch}>
                signup here
            </span>
        </p>
    </div>
);
