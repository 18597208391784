import React from "react";

export default () => (
    <div style={{ padding: '50px 20px 10px' }}>
            {/* <div>
                <ul>
                    <li>FAQ</li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div> */}
            <div className="text-muted" style={{ lineHeight: 1.2, 
                fontSize: 14, 
                padding: '5px 25px', 
                color: '#9f9f9f', 
                textAlign: 'center', 
                backgroundColor: '#f7f5ed',
                padding: 15 }}>
                <p>This service is not created by, affiliated with, or backed by the USPTO. Our services do not constitute a legal advice and we do not take any responsibility in the accuracy and completeness of our data.</p>
            </div>
        <div style={{ paddingTop: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <p style={{ color: "grey", verticalAlign: "center" }}> © 2019-2020, Merch Protect&trade; LLC </p>
        </div>
    </div>
);
