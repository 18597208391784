import React from "react";
import { personalList, itemTag, itemValue } from "../styles/list.module.scss";

export default ({ list }) => (
    <ul className={personalList}>
        {list.map(item => (
            <li>
                <p className={itemTag}>{item.title}</p>
                <p className={itemValue}>{item.value}</p>
            </li>
        ))}
    </ul>
);
