import React from 'react'
import "./index.scss";

/**
 * 
 * @param {object} props
 * @param {number} props.number 
 * @param {string} props.text 
 * @param {("style-2")} props.theme 
 */
export default function NumberBlock({ number, text, theme } = { number: 0, text: '', theme: ''}) {
    return (
        <div className={`number-block shadow number-block--${theme}`}>
            <p className="number-block__number">{number.toLocaleString()}</p>
            <p className="number-block__text">{text}</p>
        </div>
    )
}
