import React, { Component } from "react";
import Spinner from "@bit/bondz.react-epic-spinners.semipolar-spinner";

import Signin from "./signin.view";
import Signup from "./signup.view";
import SignupSuccess from "./signupSuccess.view";
import axios from "axios";
import { connect } from "react-redux";
import { signin, login } from "../../redux/actions";

import { spinner } from "../../styles/main/searchbox.module.scss";
class Login extends Component {
    state = {
        loading: false,
        signup: false,
        username: "",
        email: "",
        password: "",
        password2: "",
        passwordMatch: null,
        state: "",
        name: "",
        error: false,
        errorPosition: null,
        message: null,
        terms: false,
        privacyPolicy: false
    };

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.signup) {
            this.setState({
                signup: true
            });
        }
    }

    componentDidUpdate() {
        // if (this.props.loginError && this.state.loggingIn) {
        //     this.setState({ loggingIn: false });
        // }
    }

    handleSubmit = (e, values) => {
        if (!e) { // signup
            this.setState({ loading: true }, () => {
                axios
                    .post(
                        process.env.NODE_ENV !== "production"
                            ? "/user/signup"
                            : "https://api.merchprotect.com/user/signup",
                        {
                            ...values
                        }
                    )
                    .then(response => {
                        this.setState({ loading: false });
                        this.props.signin(response.data.token);
                    })
                    .catch(err => {
                        if (err.response.data.message) {
                            this.setState({
                                error: true,
                                message: err.response.data.message,
                                loading: false
                            });
                        } else {
                            this.setState({
                                error: true,
                                message: err.message,
                                loading: false
                            });
                        }
                        console.log(err);
                    });
            });
        } else if (e.target.id === "signin") {
            e.preventDefault();
            this.props.login(this.state, this.props.history, this.props.location);
        }
    };

    handleSwitch = () => {
        this.setState({
            signup: !this.state.signup,
            error: false,
            message: null
        });
    };

    handleInputChange = e => {
        let id = e.target.id;
        let obj = {};
        let value = e.target.value;
        let validation = {};

        if (id === "email") {
            obj.email = value;
        } else if (id === "name") {
            validation = this.validate("name", value);
            obj.name = value;
        } else if (id === "username") {
            validation = this.validate("username", value);
            obj.username = value;
        } else if (id === "password") {
            validation = this.validate("password", value);
            obj.password = value;
        } else if (id === "password2") {
            let passwordMatch = value === "" ? null : this.state.password !== value ? false : true;
            obj.passwordMatch = passwordMatch;
            obj.password2 = value;
            if (!passwordMatch) {
                validation = { message: "passwords do not match", position: id };
            }
        } else if (id === "state") {
            obj.state = value;
        } else if (id === "terms") {
            obj.terms = e.target.checked;
        } else if (id === "privacy-policy") {
            obj.privacyPolicy = e.target.checked;
        }

        if (typeof validation.message === "string") {
            obj.error = true;
            obj.message = validation.message;
            obj.errorPosition = validation.position;
        } else {
            obj.error = false;
            obj.message = "";
            obj.errorPosition = null;
        }
        this.setState(obj);
    };

    validate(position, data) {
        switch (position) {
            case "password":
                if (data !== "" && data.length < 6) {
                    return { message: "password must be at least 6 charachters", position };
                } else if (!/[a-zA-Z]/gi.test(data)) {
                    return {message: "Password must contain an alphabetical letter"}
                } else if (!/[1-9]/gi.test(data)) {
                    return {message: "Password must contain a number"}
                } else {
                    return false;
                }
            case "username":
                if (data !== "" && data.length < 4) {
                    return { message: "username must be at least 5 charachters", position };
                } else {
                    return false;
                }
            case "name":
                if (data !== "" && (!data.includes(" ") || data.length < 5)) {
                    return { message: "must be a full name", position };
                } else {
                    return false;
                }
            default:
                return false;
        }
    }

    render() {
        const { signup, loading } = this.state;
        if (this.props.signedUp) {
            return <SignupSuccess />;
        } else if (loading) {
            return <div className="py-5"><Spinner color="#b9b9b9" size="75" className={spinner} /></div>;
        } else if (!signup) {
            return (
                <Signin
                    {...this.state}
                    {...this.props}
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                    handleSwitch={this.handleSwitch}
                />
            );
        } else {
            return (
                <Signup
                    {...this.state}
                    handleFormSubmit={this.handleSubmit}
                    // handleInputChange={this.handleInputChange}
                    handleSwitch={this.handleSwitch}
                />
            );
        }
    }
}

let mapDispatchToProps = {
    signin,
    login
};

const mapStateToProps = state => {
    return {
        signedUp: state.auth.signedup,
        signedin: state.auth.signedin,
        loginError: state.auth.error,
        loggingIn: state.auth.loggingIn
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
