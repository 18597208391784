import { SIGNIN, LOGOUT, LOGIN_ERROR, UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_LOADING, RESET_UPDATE_SUCCESS_FLAG, SIGNIN_LOADING } from "../types";
import jwt from 'jsonwebtoken';
import { getDecodedJwtState } from '../../util/index';

const defaultState = {
    signedin: false,
    token: "",
    signedup: false,
    error: false,
    accountType: '',
    userId: null,
    updateAccountLoading: null,
    updateAccountSuccess: false,
    email: '',
    username: '',
    state: '',
    phone: '',
    plan: 'FREE',
    merchLimit: 0,
    loggingIn: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SIGNIN_LOADING:
            return {
                ...state,
                loggingIn: true,
            };
        case SIGNIN:
            const { token } = action.payload;
            const decodedState = getDecodedJwtState(token);
            return {
                ...state,
                signedup: action.payload.signedup ? true : false,
                loggingIn: false,
                ...decodedState
            };
        case LOGOUT:
            return {
                ...defaultState,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loggingIn: false,
                error: action.payload
            };
        case UPDATE_ACCOUNT_SUCCESS: 
            return {
                ...state,
                username: action.payload.form.username,
                email: action.payload.form.email,
                name: action.payload.form.name,
                phone: action.payload.form.phone,
                state: action.payload.form.state,
                updateAccountLoading: false,
                updateAccountSuccess: true,
                token: action.payload.token,
            }
        case UPDATE_ACCOUNT_LOADING: 
            return {
                ...state,
                updateAccountLoading: true
            }
        case RESET_UPDATE_SUCCESS_FLAG:
            return {
                ...state,
                updateAccountSuccess: false,
            }
        default:
            return state;
    }
};
