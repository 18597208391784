import React from "react";
import urls from "../../config/urls";
import { Formik } from "formik";
import * as yup from 'yup'
import {
    container,
    wrapper,
    title,
    form,
    input,
    text,
    button,
    switchPhrase,
    switchP,
    notmatch,
    match,
    errorMessage,
    errorWrapper,
    easy
} from "../../styles/login.module.scss";

import { states, countries } from "../../util/constants";

const validationSchema = yup.object().shape({
    username: yup.string().required().label("Username"),
    email: yup.string().matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Email is invalid').required().label("Email"),
    // /** @link https://www.ocpsoft.org/tutorials/regular-expressions/password-regular-expression/ */
    password: yup.string().matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/, `Password must be:
    * At least one digit,
    * At least one lowercase character,
    * At least one uppercase character,
    * At least one special character.`).required(),
    password2:  yup.string()
        .oneOf([yup.ref('password'), null], "Passwords must match")
        .required('Password confirmation is required'),
    state: yup.ref('password') === 'US' ? yup.string().required() : yup.string(),
    country: yup.string().required(),
    name: yup.string().matches(/[a-z]+\s[a-z]+/ig, "Must be a full name").required().label("Full Name"),
    terms: yup.bool().oneOf([true], 'Must agree to our terms and conditions'),
    privacyPolicy: yup.bool().oneOf([true], 'Must agree to our privacy policy') 
  });

export default ({
    handleFormSubmit,
    error,
    message,
    handleSwitch,
}) => {
    // let passClass = passwordMatch === false ? notmatch : passwordMatch === true ? match : null;
    return (
        <div className={container}>
            <div className={wrapper}>
                <p className={title}>SIGN UP</p>
                {error && errorMessage ? (
                    <div className={errorWrapper}>
                        <p className={`${errorMessage}`}>{message}</p>
                    </div>
                ) : null}
                <Formik 
                    initialValues={{ 
                        username: "",
                        email: "",
                        password: "",
                        password2: "",
                        passwordMatch: null,
                        state: "",
                        country: "",
                        name: "",
                        terms: false,
                        privacyPolicy: false 
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        debugger;
                        setSubmitting(true);
                        handleFormSubmit(null, values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form
                            className={form}
                            id="signup"
                            onSubmit={handleSubmit}
                        >
                            <input
                                className={`${input} ${text}`}
                                placeholder="username"
                                type="text"
                                name="username"
                                id="username"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                            />
                            {errors.username && touched.username && <div className={errorWrapper}>
                                    <p className={`${errorMessage} ${easy}`}>{errors.username}</p>
                                </div>}
                            <input
                                className={`${input} ${text}`}
                                placeholder="name"
                                type="text"
                                name="name"
                                id="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                            />
                            {errors.name && touched.name && (
                                <div className={errorWrapper}>
                                    <p className={`${errorMessage} ${easy}`}>{errors.name}</p>
                                </div>
                            )}
                            <input
                                className={`${input} ${text}`}
                                placeholder="email"
                                type="email"
                                name="email"
                                id="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                            />
                             {errors.email && touched.email && (
                                <div className={errorWrapper}>
                                    <p className={`${errorMessage} ${easy}`}>{errors.email}</p>
                                </div>
                            ) }
                            <input
                                className={`${input} ${text}`}
                                placeholder="password"
                                type="password"
                                name="password"
                                id="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                            />
                            {errors.password && touched.password && (
                                <div className={errorWrapper}>
                                    <pre className={`${errorMessage} ${easy}`} style={{textAlign: 'start'}}>{errors.password}</pre>
                                </div>
                            )}
                            <input
                                className={`${input} ${text}`}
                                placeholder="password"
                                type="password"
                                name="password2"
                                id="password2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password2}
                            />
                            {errors.password2 && touched.password2 &&  (
                                <div className={errorWrapper}>
                                    <p className={`${errorMessage} ${easy}`}>{errors.password2}</p>
                                </div>
                            )}
                            <select 
                                name="country" id="country" 
                                className={`${input} ${text}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.country}
                            >
                                <option value="" disabled={true}>Select Country</option>
                                {countries.map(country => <option key={country.value} value={country.value}>{country.label}</option>)}
                            </select>
                            {errors.country && touched.country &&  (
                                <div className={errorWrapper}>
                                    <p className={`${errorMessage} ${easy}`}>{errors.country}</p>
                                </div>
                            )}
                            
                            {values.country === 'US' && <> <select 
                                name="state" id="state" 
                                className={`${input} ${text}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.state}
                            >
                                <option value="" disabled={true}>Select State</option>
                                {states.map(state => <option key={state[0]} value={state[0]}>{state[1]}</option>)}
                            </select>
                            {errors.state && touched.state &&  (
                                <div className={errorWrapper}>
                                    <p className={`${errorMessage} ${easy}`}>{errors.state}</p>
                                </div>
                            )}</>}
                            
                            <label htmlFor="privacyPolicy" className="d-flex align-items-center mb-1">
                                <input type="checkbox" 
                                    name="privacyPolicy" 
                                    id="privacyPolicy"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.privacyPolicy} 
                                    className="mr-2"/>
                                <span className="lead" style={{fontSize: 14}}>
                                    I agree to <a style={{ textDecoration: 'underline'}} target="_blank"  rel="noopener noreferrer" href={`${urls.API_BASE}/privacy-policy.html`}>Privacy policy</a>.
                                </span>
                            </label>
                            {errors.privacyPolicy && touched.privacyPolicy && (
                                <div className={errorWrapper}>
                                    <p className={`${errorMessage} ${easy}`}>{errors.privacyPolicy}</p>
                                </div>
                            )}
                            <label htmlFor="terms" className={`d-flex align-items-center mb-3`}>
                                <input type="checkbox" 
                                    name="terms" id="terms" 
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.terms}
                                    className="mr-2"/>
                                <span className="lead" style={{fontSize: 14}}>
                                    I agree to <a style={{ textDecoration: 'underline'}} target="_blank"  rel="noopener noreferrer" href={`${urls.API_BASE}/terms-and-conditions.html`}>terms and conditions</a>.
                                </span>
                            </label>
                            {errors.terms && touched.terms &&  (
                                <div className={errorWrapper}>
                                    <p className={`${errorMessage} ${easy}`}>{errors.terms}</p>
                                </div>
                            )}
                            <button className={`${input} ${button}`} type="submit">Submit</button>
                        </form>
                    )}
                </Formik>
            </div>
            <p className={switchP}>
                Already a member?{" "}
                <span className={switchPhrase} onClick={handleSwitch}>
                    login here
                </span>
            </p>
        </div>
    );
};
