import React, { Component } from "react";
import Error from '../error';

export default class ErrorBoundary extends Component {
    state = {
        error: false,
    }

    componentDidCatch(err) {
        let errMessage;
        if (err.response && err.response.data) {
            errMessage = err.response.data;
        } else if (err.message) {
            errMessage = err.message;
        } else {
            errMessage = 'something went wrong :' + err;
        }

        this.setState({ error: errMessage });
    }

    render() {
        const { error } = this.state;
        if(!error) {
            return (
                this.props.children
            )
        } else {
            return (<Error message={error}/>)
        }
    }
}