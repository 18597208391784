import React, {useState, useEffect} from "react";
import {
    errorWrapper,
    title,
    errorMessage,
    lastLine,
    errorWrapperOneLine,
    errorMessageXSymbol,
    errorMessageXSymbolOneLine
} from "../styles/error.module.scss";

export default ({ message, oneLine, className }) => {
    const [showError, setShowError] = useState(true);

    if (oneLine) {
        return (
            <div hidden={!showError} className={`${errorWrapperOneLine} ${className}`}>
                <p className={errorMessage}>{message}</p>
                <span className={errorMessageXSymbolOneLine} onClick={() => setShowError(false)}>x</span>
            </div>
        );
    }
    return (
        <div hidden={!showError} className={errorWrapper}>
            <p className={title}>Sorry!</p>
            <p className={errorMessage}>{message}</p>
            <p className={lastLine}>please try again or email us at error@merchprotect.com</p>
            <span className={errorMessageXSymbol} onClick={() => setShowError(false)}>x</span>
        </div>
    );
};
