import React from "react";
import { container, wrapper, text } from "../../styles/signupSuccess.module.scss";

export default ({ message }) => (
    <div className={container}>
        <div className={wrapper}>
            <p className={text}>
                {message
                    ? message
                    : "You have signed up successfully! We sent a verification link to your email. Please click on that link to verify your account."}
            </p>
            {/* <a>Send Again</a> */}
        </div>
    </div>
);
