import axios from "axios";
import {
    SIGNIN,
    LOGOUT,
    LOGIN_ERROR,
    ADD_NEWMERCH,
    MERCH_SUBMIT_ERROR,
    NEWMERCH_LOADING,
    TRACK,
    PRODUCT_TRACKING_ERROR,
    UNTRACK,
    FETCH_MERCH_ERROR,
    DELETE_MERCH,
    TRACK_LOADING,
    IGNORE_MARK,
    TRACK_MARK,
    POPULATE_MERCH,
    MERCH_LOADING,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_LOADING,
    UPDATE_ACCOUNT_ERROR,
    RESET_UPDATE_SUCCESS_FLAG,
    SAVE_IGNOREWORDS_LOADING,
    SAVE_IGNOREWORDS_ERROR,
    SAVE_IGNOREWORDS_SUCCESS,
    SAVE_IGNOREPHRASES_LOADING,
    SAVE_IGNOREPHRASES_ERROR,
    SAVE_IGNOREPHRASES_SUCCESS,
    SIGNIN_LOADING,
} from "./types";
import urls from "../config/urls";
import handleMobileHover from "../util/mobileHover";
import { appendQuery } from "../util"

axios.defaults.headers.common["x-jwt"] = localStorage.getItem("jwt") || null;
export const signin = token => {
    localStorage.setItem("jwt", token);
    axios.defaults.headers.common["x-jwt"] = localStorage.getItem("jwt");
    return { type: SIGNIN, payload: { token, signedup: true } };
};

export const login = (state, history, location) => async dispatch => {
    try {
        dispatch({ type: SIGNIN_LOADING, payload: {} });

        let response = await axios.post(
            process.env.NODE_ENV !== "production"
                ? "/user/signin"
                : "https://api.merchprotect.com/user/signin",
            { email: state.email, password: state.password }
        );

        dispatch({ type: SIGNIN, payload: { token: response.data.token } });
        localStorage.setItem("jwt", response.data.token);
        axios.defaults.headers.common["x-jwt"] = localStorage.getItem("jwt");

        let pathname =
            location.state && location.state.redirect
                ? location.state.redirect
                : "/dashboard/client";
        history.push(pathname, { ...location.state });
    } catch (err) {
        if (err.response?.status >= 500) {
            dispatch({ type: LOGIN_ERROR, payload: 'Server Error occurred' });
        } else {
            dispatch({ type: LOGIN_ERROR, payload: 'Email or Password is incorrect' });
        }
    }
};

export const logout = () => async dispatch => {
    localStorage.removeItem("jwt");
    try {
        await axios.delete(
            process.env.NODE_ENV !== "production"
                ? "/user/signout"
                : "https://api.merchprotect.com/user/signout"
        );
        dispatch({ type: LOGOUT, payload: {} });
    } catch (err) {
        // TODO: handle logout error
    }
};

export const addnewMerch = merch => async dispatch => {
    try {
        dispatch({ type: NEWMERCH_LOADING, payload: null });
        let response = await axios.post(
            process.env.NODE_ENV !== "production"
                ? "/user/addMerch"
                : "https://api.merchprotect.com/user/addMerch",
            merch
        );

        if (response.data.success) {
            merch.track = false;
            merch.status = null;
            merch.serial_no = response.data.id;
            merch.show = true;
            merch.discr_1 = merch.description1;
            merch.discr_2 = merch.description2;
            merch.description = merch.description;
            merch.ASIN = merch.asin;

            dispatch({ type: ADD_NEWMERCH, payload: merch });
        } else {
            throw response;
        }
    } catch (err) {
        dispatch({ type: MERCH_SUBMIT_ERROR, payload: err });
    }
};

export const trackProduct = id => async dispatch => {
    if (typeof id === "string") id = parseInt(id);

    dispatch({ type: TRACK_LOADING, payload: id });
    try {
        let { data } = await axios.get(
            process.env.NODE_ENV !== "production"
                ? `/user/track?id=${id}`
                : `https://api.merchprotect.com/user/track?id=${id}`
        );
        let { status, statusStr, alerts, success, ignoredCommonWords, all_adjacent_possible_combs } = data;
        if (success) {
            dispatch({
                type: TRACK,
                payload: { id, status, statusStr, alerts, ignoredCommonWords, all_adjacent_possible_combs }
            });
        } else {
            dispatch({
                type: PRODUCT_TRACKING_ERROR,
                payload: { id, message: 'Something went wrong, please try again.' }
            });
        }
    } catch (err) {
        dispatch({
            type: PRODUCT_TRACKING_ERROR,
            payload: { id, message: (err.response && err.response.data) || err.message }
        });
    }
};

export const untrackProduct = id => async dispatch => {
    if (typeof id === "string") id = parseInt(id);
    try {
        let response = await axios.get(
            process.env.NODE_ENV !== "production"
                ? `/user/untrack?id=${id}`
                : `https://api.merchprotect.com/user/untrack?id=${id}`
        );
        if (response.data.success) {
            dispatch({ type: UNTRACK, payload: id });
        } else {
            throw response.status + " Error: something went wrong on the server";
        }
    } catch (err) {
        dispatch({
            type: PRODUCT_TRACKING_ERROR,
            payload: { id, message: (err.response && err.response.data) || err.message }
        });
    }
};

export const populateMerch = (pageNumber, count, filters) => async dispatch => {
    const {searchTerm: search, filter } = {...filters};

    try {
        dispatch({ type: MERCH_LOADING, payload: {} });

        let url = '/user/merch';

        if (pageNumber) url = appendQuery(url, 'pageNumber', pageNumber);
        if (count) url = appendQuery(url, 'count', count);
        if (search) url = appendQuery(url, 'search', search);
        if (filter) url = appendQuery(url, 'filter', filter);

        if (process.env.NODE_ENV === "production") url = 'https://api.merchprotect.com' + url;

        const response = await axios.get(url);

        if (response.data.success) {
            response.data.merch.forEach(item => {
                item.tracked = item.track;
                item.show = true;
            });
            dispatch({ type: POPULATE_MERCH, payload: { 
                    merch: response.data.merch, 
                    totalPages: response.data.totalPages, 
                    pageNumber,
                    filter,
                    totalTracked: response.data.totalTracked,
                    totalDanger: response.data.totalDanger,
                    totalNotSeen: response.data.totalNotSeen
                } 
            });
            setTimeout(handleMobileHover, 50)
        } else {
            dispatch({ type: FETCH_MERCH_ERROR, payload: 'Something went wrong. Try refreshing the page.' });
        }
    } catch (err) {
        dispatch({ type: FETCH_MERCH_ERROR, payload: err });
    }
};

export const deleteProduct = id => async dispatch => {
    if (typeof id === "string") id = parseInt(id);
    try {
        const response = await axios.delete(
            process.env.NODE_ENV !== "production"
                ? `/user/delete?id=${id}`
                : `https://api.merchprotect.com/user/delete?id=${id}`
        );
        if (response.data.success) {
            dispatch({ type: DELETE_MERCH, payload: id });
        } else {
            throw new Error(response.status + ": something went wrong: " + response.data.message);
        }
    } catch (err) {
        // TODO: handle error
    }
};

export const ignoreMark = (markId, merchId, deleteAlert = false) => async dispatch => {
    if (typeof merchId === "string") merchId = parseInt(merchId);
    try {
        const {
            data: { status, statusStr }
        } = await axios.get(urls.API_BASE +  `/user/ignoremark?markid=${markId}&merchid=${merchId}&deletealert=${deleteAlert}`);

        dispatch({ type: IGNORE_MARK, payload: { markId, merchId, status, statusStr, delete: deleteAlert } });
    } catch (err) {
        // TODO:
    }
};

export const trackMark = (markId, merchId) => async dispatch => {
    if (typeof merchId === "string") merchId = parseInt(merchId);
    try {
        const {
            data: { status, statusStr }
        } = await axios.get(
            process.env.NODE_ENV !== "production"
                ? `/user/trackmark?markid=${markId}&merchid=${merchId}`
                : `https://api.merchprotect.com/user/trackmark?markid=${markId}&merchid=${merchId}`
        );
        dispatch({ type: TRACK_MARK, payload: { markId, merchId, status, statusStr } });
    } catch (err) {
        // TODO:
    }
};

export const updateAccountInfo = form => async dispatch => {
    dispatch({type: UPDATE_ACCOUNT_LOADING, payload: {}})
    try {
        let response = await axios.post(urls.API_BASE + '/user/info', form);
        localStorage.setItem('jwt', response.data.token)
        dispatch({ type: UPDATE_ACCOUNT_SUCCESS, payload: {form, token: response.data.token}});
    } catch(err) {
        console.log(err)
    }
};

export const resetSuccessFlag = () => ({type: RESET_UPDATE_SUCCESS_FLAG, payload: {}})

export const saveIgnoreWords = updatedIgnoreWords => async dispatch => {
    try {
        if(!Array.isArray(updatedIgnoreWords)) throw new Error('saveIgnoreWords only accepts an argument of type array');

        dispatch({ type: SAVE_IGNOREWORDS_LOADING })

        const response = await axios.post(urls.API_BASE +'/user/ignorewords', { updatedIgnoreWords });
        if (response.data.success) {
            dispatch({type: SAVE_IGNOREWORDS_SUCCESS, payload: updatedIgnoreWords });
        } else {
            throw new Error(response.data.message || 'failed to save new ignore words with response code: ' + response.code);
        }
    } catch(err) {
        dispatch({ type: SAVE_IGNOREWORDS_ERROR, payload: {message: err.message}});
    }
}
export const saveIgnorePhrases = updatedIgnorePhrases => async dispatch => {
    try {
        if(!Array.isArray(updatedIgnorePhrases)) throw new Error('saveIgnorePhrases only accepts an argument of type array');

        dispatch({ type: SAVE_IGNOREPHRASES_LOADING })

        const response = await axios.post(urls.API_BASE +'/user/ignorephrases', { updatedIgnorePhrases });
        if (response.data.success) {
            dispatch({type: SAVE_IGNOREPHRASES_SUCCESS, payload: updatedIgnorePhrases });
        } else {
            throw new Error(response.data.message || 'failed to save new ignore words with response code: ' + response.code);
        }
    } catch(err) {
        dispatch({ type: SAVE_IGNOREPHRASES_ERROR, payload: {message: err.message}});
    }
}
