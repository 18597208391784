import React, { Component } from "react";
import { MdList } from "react-icons/md";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../redux/actions";

import Dashboard from "../sharedComponents/dashboard";

import { header, list, list_item, menu_btn, open, list_subitem } from "../styles/header.module.scss";

class Header extends Component {
    state = {
        menuOpen: false
    };

    handleLogout = () => {
        this.props.logout();
    };

    handleBtnClick = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    handleLinkClick = () => {
        this.setState({
            menuOpen: false
        });
    };

    render() {
        const { signedin } = this.props;
        const { menuOpen } = this.state;

        return (
            <header className={header}>
                <MdList
                    className={menuOpen ? `${menu_btn} ${open}` : menu_btn}
                    onClick={this.handleBtnClick}
                />
                <ul className={menuOpen ? `${list} ${open}` : `${list} shadow`}>
                    <li className={list_item}>
                        <Link to="/" onClick={this.handleLinkClick}>
                            main
                        </Link>
                    </li>
                    <li className={list_item}>
                        Info
                        <div className={list_subitem}>
                            <ul>
                                <li>
                                    <a href="/statics/article/">
                                        Articles & News
                                    </a>
                                </li>
                                <li>
                                    <Link to="/info" onClick={this.handleLinkClick}>
                                        About MerchProtect
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className={list_item}>
                        <Link to="/plans" onClick={this.handleLinkClick}>
                            Plans
                        </Link>
                    </li>
                    <li className={list_item}>
                        <Link to="/faq" onClick={this.handleLinkClick}>
                            FAQ
                        </Link>
                    </li>
                    <li className={list_item}>
                        <Link to="/contactus" onClick={this.handleLinkClick}>
                            contact
                        </Link>
                    </li>
                    {signedin ? ( <>
                        <li className={list_item}>
                            <Link to="/dashboard/client">
                                dashboard
                            </Link>
                        </li>
                        <li className={list_item}>
                            <Link to="#" onClick={this.handleLogout}>
                                logout
                            </Link>
                        </li>
                        </>
                    ) : (
                        <li className={list_item}>
                            <Link to="/login" onClick={this.handleLinkClick}>
                                login
                            </Link>
                        </li>
                    )}
                </ul>
                {/* {signedin ? <Dashboard /> : null} */}
            </header>
        );
    }
}

function mapStateToProps(state) {
    return {
        signedin: state.auth.signedin
    };
}
let mapDispatchToProps = {
    logout
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
