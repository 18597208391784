import React from "react";
import { noResults, noResults_text } from "../../styles/main/results.module.scss";

export default () => (
    <div className={noResults}>
        <p className={noResults_text}>
            No alerting trademarks found
        </p>
    </div>
);
