import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { codes, codesStatusMap } from '@merchprotect/trademark-status-codes';
import QuestionMark from '../../../sharedComponents/questionMark';

import './progressBar.scss';

const MILESTONE_MAP = [0, 20, 45, 70, 100]

function ProgressBar({ statusCode }) {
    const [mounted, setMounted] = useState(false);
    const currentStageNum = getCurrentStageNum(statusCode);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
        }
    }, [])

    return (
        <div id="progress-bar" className="pb_container">
            <div className="pb_wrapper">
                <div className={`pb_detail-line ${codes[statusCode].soonDead ? 'pb_detail-line--dying' : ''}`} 
                    style={{ 
                        left: mounted ? getProgressWidth(statusCode) : 0, 
                        opacity: mounted ? 1 : 0.5, 
                        transition: 'all 1s linear' 
                    }} />
                <span className={`pb-milestone_text 
                    ${currentStageNum === 5 && 'pb-milestone_text--last'}  
                    ${mounted && 'pb-milestone_text--open'}  
                    ${codes[statusCode].soonDead ? 'pb-milestone_text--dying' : ''}`}
                    style={{ 
                        left: mounted && currentStageNum !== 5 ?  getProgressWidth(statusCode, -0.2) : 'unset', 
                        right: mounted && currentStageNum === 5 ?  0 : '400px', 
                        opacity: mounted ? 1 : 0.5, 
                        transition: 'all 1s linear' 
                    }}
                >
                    {codesStatusMap[statusCode]} {codes[statusCode].soonDead && <span className="pb-milestone_text_note">(COULD DIE SOON)</span>} 
                    {codes[statusCode].detail && <QuestionMark message={codes[statusCode].detail} width={250} iconWidth={12} />}
                </span>

                <div className="pb_bar pb_bar-base"></div>
                <div className={`pb_bar pb_bar-progress ${mounted && 'pb_bar-progress--open'}
                    ${codes[statusCode].soonDead ? 'pb_bar-progress--dying' : ''}`} 
                    style={{ width: mounted ? getProgressWidth(statusCode) : 0, transition: 'width 0.7s ease-in' }}>
                </div>
                <div className={`pb-milestone pb-milestone--${getClassModifier(1, currentStageNum)}`} style={{ left: `${MILESTONE_MAP[0]}%` }}>
                    <span className="pb-milestone_status-code">NEW</span>
                </div>
                <div className={`pb-milestone pb-milestone--${getClassModifier(2, currentStageNum)}`} style={{ left: `${MILESTONE_MAP[1]}%` }}>
                    <span className="pb-milestone_status-code">{window.innerWidth <= 500 ? 'ACCEP.' : 'ACCEPTED'}</span>
                </div>
                <div className={`pb-milestone pb-milestone--${getClassModifier(3, currentStageNum)}`} style={{ left: `${MILESTONE_MAP[2]}%` }}>
                    <span className="pb-milestone_status-code">PUBLISHED</span>
                </div>
                <div className={`pb-milestone pb-milestone--${getClassModifier(4, currentStageNum)}`} style={{ left: `${MILESTONE_MAP[3]}%` }}>
                    <span className="pb-milestone_status-code">PASSED</span>
                </div>  
                <div className={`pb-milestone pb-milestone--pending pb-milestone--${getClassModifier(5, currentStageNum)}`} 
                    style={{ 
                        left: `${MILESTONE_MAP[4] - 1}%`,
                        textAlign: "right" 
                    }}>
                    <span className="pb-milestone_status-code" style={{ left: '-115px' }}>{window.innerWidth <= 500 ? 'REG.' : 'REGISTERED'}</span>
                </div>

            </div>
        </div>
    )
}

ProgressBar.propTypes = {
    statusCode: PropTypes.string,
}

function getCurrentStageNum(statusCode) {
    let stage = codes[statusCode].stage;
    
    switch (stage) {
        case 'REGISTERED': return 5;
        case 'PRE-REGISTRATION': return 4;
        case 'PUBLISHED': return 3;
        case 'ACCEPTED': return 2;
        case 'NEW': return 1;
    
        default:
            return 0
    }
}

function getClassModifier(milestone, currentStageNum) {
    if (milestone === currentStageNum) return 'current';
    if (currentStageNum > milestone) return 'done';
    return 'pending';

}

function getProgressWidth(statusCode, add = 0) {
    let currentStageNum = getCurrentStageNum(statusCode);
    if (currentStageNum === 1) return `${MILESTONE_MAP[currentStageNum - 1] + add + 10}%`;
    return `${MILESTONE_MAP[currentStageNum - 1] + add}%`;
}

export default ProgressBar

