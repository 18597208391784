import React, { useState, useEffect, useReducer } from 'react';
import './integrityTesting.scss';
import { layout_limited, layout_limited_md } from '../../styles/layout.module.scss';
import '../../styles/shared/lists.scss';
import { note } from '../../styles/info/info.module.scss';
import axios from 'axios';
import urls from '../../config/urls';
import Loader from 'react-loader-spinner'
import ErrorBar from '../../sharedComponents/error';
import NumberBlock from '../common/numberBlock/NumberBlock';
import QuestionMark from '../../sharedComponents/questionMark';

export default () => {
    const initialState = {
        serialNumber: null,
        inputError: null,
        httpError: null,
        httpErrorMsg: null,
        tests: [],
        testsFailed: [],
        twoDaysOutdated: false,
        failMessages: [],
        loading: false,
        noResults: false,
        integrityTests: [],
        totalTests: 0,
        integrityTestsLoading: false,
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        tests, serialNumber, loading, integrityTestsLoading, noResults, 
        inputError, httpError, failMessages, testsFailed, integrityTests,
        totalTests, twoDaysOutdated, httpErrorMsg
    } = state;

    function reducer(state, action) {
        switch (action.type) {
            case 'TEST_LOADING': return {...state, loading: true, tests: [], twoDaysOutdated: false, testsFailed: [], failMessages: [], noResults: false }
            case 'INTEGRITY_TEST_LOADING': return {...state, integrityTestsLoading: true, integrityTests: [], totalResults: 0 }
            case 'INPUT_ERROR': return {...state, inputError: action.payload, loading: false }
            case 'HTTP_ERROR': return {...state, httpError: true, loading: false,  httpErrorMsg: action.payload}
            case 'NO_RESULTS': return {...state, noResults: true, loading: false }
            case 'ADD_INTEGRITY_TESTS': return {...state, integrityTests: action.payload.results, totalTests: action.payload.totalTests, integrityTestsLoading: false }
            case 'UPDATE_SERIAL': return {...state, serialNumber: action.payload }
            case 'ADD_TESTS': return {
                    ...state,
                    httpError: false,
                    loading: false,
                    tests: action.payload.tests,
                    twoDaysOutdated: action.payload.twoDaysOutdated,
                    testsFailed: action.payload.testsFailed,
                    failMessages: action.payload.failMessages
                }
            
            default: return {...state};                
        }
    }

    let testIndexMap = {};
    if (tests?.length > 0) {
        tests.forEach((test, i) => {
            if (test.name === 'Trademark') testIndexMap.trademark = i;
            if (test.name === 'Registration No.') testIndexMap.registrationNo = i;
            if (test.name === 'Draw Code') testIndexMap.drawCode = i;
            if (test.name === 'Status') testIndexMap.status = i;
            if (test.name === 'International Classes') testIndexMap.intlClasses = i;
            if (test.name === 'Intl. Classes Status') testIndexMap.intlClassesStatus = i;
        })

    }

    let fetchWeeklyResults;

    useEffect(() => {
        dispatch({type: 'INTEGRITY_TEST_LOADING'});
        fetchWeeklyResults();

        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });
    }, [])

    fetchWeeklyResults = async () => {
        try {
            let result = await axios.get(urls.API_BASE+ '/weekly-integrity-results?count=30')
            if (result.status === 200) {
                console.log(result.data);
                dispatch({type: 'ADD_INTEGRITY_TESTS', payload: { results: result.data.data.results, totalTests: result.data.data.totalTests }});
            } else {
                throw new Error();
            }
        } catch(err) {
            dispatch({type: 'HTTP_ERROR'});
        }
    } 
    
    const handleInputSubmit = async (e) => {
        e.preventDefault();
        if (!serialNumber) return;
        
        if (typeof serialNumber !== 'string') {
            let serialNumber = String(serialNumber);
        }
        if (serialNumber?.length < 8 || serialNumber?.length > 8) return dispatch({type: 'INPUT_ERROR', payload: 'Serial number must contain 8 numbers.'})
        
        try {
            dispatch({type: 'TEST_LOADING'});

            let response = await axios.get(urls.API_BASE + `/testdata?serials=${serialNumber}`)
            if (response.status === 200 && response.data?.success) {
                let compareResults = response.data?.data;
                let testsTemp = compareResults.tests[serialNumber];
                
                if (testsTemp) {
                    let failMessagesTemp = tests?.filter(test => test.failed)?.map(test => test.failedMessage) || [];
                    let testsFailedTemp = compareResults.fail;
                    
                    dispatch({
                        type: "ADD_TESTS", 
                        payload: {
                            tests: testsTemp,
                            testsFailed: testsFailedTemp,
                            twoDaysOutdated: isTwoDaysOutdated(testsTemp),
                            failMessages: failMessagesTemp
                        }
                    })
                } else {
                    dispatch({type: 'NO_RESULTS'});
                }
                
            } else {
                dispatch({type: 'HTTP_ERROR'});
            }
        } catch (err) {
            if (err.response?.status < 500) {
                dispatch({ type: 'HTTP_ERROR', payload: err.response.data})
            } else {
                dispatch({type: 'HTTP_ERROR'});
            }
        }
            
    }

    const downloadSerialsTested = (index) => {
        const serials = integrityTests[index].trademarks_tested;
        const blob = new Blob([serials], {type: 'text/plain'})
        const a = document.createElement('a');
        
        a.href = URL.createObjectURL(blob);
        a.target = "_blank";
        a.click();
    }

    function getTotalTestsCount() {
        let count = 0;
        integrityTests.forEach(test => {
            count += test.tests_count;
        })
        return count;
    }

    function isTwoDaysOutdated(tests) {
        let result = false;

        // eslint-disable-next-line no-unused-expressions
        tests?.forEach(test => {
            if (test.twoDaysOutdated) {
                result = true;
            }
        })
        
        return result;
    }

    return ( 
        <div id="integrity" className={' px-3 px-lg-2 ' + layout_limited_md}>
            <div className="pt-5 mb-5">
                <h1 className="lead text-center">Database Integrity Testing</h1>
            </div>
            <div className="container-fluid">
                <div className="row mb-3">
                    <div className="col">
                        <p>
                            In order to insure the integrity of our database we developed a tool that we run internally on a daily basis for thousands 
                            of trademarks to insure our data matches USPTO Data. 
                        </p>
                        <p>
                            To be transparent and to gain your trust, we decided to make the results public. You can also enter any trademark serial number in the 
                            input field Below and test our data for yourself.  
                        </p>
                    </div>
                </div>
            </div>
            <div className="mb-5">
                <form id="serial_form" action="" onSubmit={handleInputSubmit}>
                    <input 
                        type="number" 
                        placeholder="enter serial number.."
                        className="serial_form-input" 
                        maxLength="8" 
                        value={serialNumber} onChange={e => dispatch({type: 'UPDATE_SERIAL', payload: e.target.value})}/>
                </form>
                {loading && 
                    <div className="d-flex justify-content-center pt-4">
                        <Loader
                            type="Grid"
                            color="#439283"
                            height={50}
                            width={50}
                        />
                    </div>
                }
                {httpError && <ErrorBar message={httpErrorMsg || "Something went wrong"}/>}
                {tests.length > 0 && <>
                    <div className="d-flex flex-wrap justify-content-center pt-5">
                        <div className="integrity__block-wrapper">
                            <div className="integrity__block shadow">
                                <p className="integrity__block-title">OUR DATA</p>
                                <div className="integrity__block-body">
                                    <div className={`integrity__block-item ${tests[testIndexMap.trademark].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.trademark].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.trademark].ours}</span>
                                    </div>
                                    <div className={`integrity__block-item ${tests[testIndexMap.registrationNo].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.registrationNo].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.registrationNo].ours}</span>
                                    </div>
                                    <div className={`integrity__block-item ${tests[testIndexMap.drawCode].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.drawCode].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.drawCode].ours}</span>
                                    </div>
                                    <div className={`integrity__block-item ${tests[testIndexMap.status].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.status].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.status].ours}</span>
                                    </div>
                                    <div className={`integrity__block-item ${tests[testIndexMap.intlClasses].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.intlClasses].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.intlClasses].ours}</span>
                                    </div>
                                    <div className={`integrity__block-item ${tests[testIndexMap.intlClassesStatus].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.intlClassesStatus].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.intlClassesStatus].ours}</span>
                                    </div>
                                    <div className={`integrity__block-item mb-3`}>
                                        <span className="integrity__block-label">Status Date</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.intlClassesStatus].oursStatusDate}</span>
                                    </div>
                                    <div className={`integrity__block-item`}>
                                        <span style={{color: '#555', fontSize: 12}}>Last updated: {tests[testIndexMap.intlClassesStatus].oursLastUpdated}</span>
                                        {/* <span className="integrity__block-value" style={{fontSize: 12}}>{tests[testIndexMap.intlClassesStatus].oursLastUpdated}</span> */}
                                    </div>
                                    <div className="integrity__block-item pt-3">
                                        <a style={{fontSize: 12}} target="_blank" href={`/?search=${serialNumber}`}>View Trademark on Our Search Engine <img src="svg/external-link.svg" width="10" height="10" alt="external link"/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="integrity__block-wrapper--middle">
                            <div className="integrity__block">
                                {testsFailed ? <>
                                    <p className="integrity__block-title">MISMATCH</p>
                                    <img src="/svg/exclamation-mark-inside-a-circle.svg" height="100" width="100"  alt=""/>
                                </> : twoDaysOutdated ? <>
                                    <p className="integrity__block-title">OUTDATED</p>
                                    <img src="/svg/exclamation-mark-inside-a-circle.svg" height="100" width="100" className="mb-3" alt=""/>
                                    <p style={{ fontSize: 14 }} className="text-danger text-center">This serial number was updated in the Uspto records yesterday and our database will pull this update tomorrow morning. Check "Status Date" to see latest update date.</p>
                                </> : <>
                                    <p className="integrity__block-title">GOOD</p>
                                    <img src="/svg/check-circle-outline.svg" height="100" width="100" alt=""/>
                                </>}
                            </div>
                        </div>
                        <div className="integrity__block-wrapper">
                            <div className=" integrity__block shadow">
                                <p className="integrity__block-title">USPTO DATA</p>
                                <div className="integrity__block-body">
                                    <div className={`integrity__block-item ${tests[testIndexMap.trademark].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.trademark].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.trademark].uspto}</span>
                                    </div>
                                    <div className={`integrity__block-item ${tests[testIndexMap.registrationNo].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.registrationNo].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.registrationNo].uspto}</span>
                                    </div>
                                    <div className={`integrity__block-item ${tests[testIndexMap.drawCode].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.drawCode].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.drawCode].uspto}</span>
                                    </div>
                                    <div className={`integrity__block-item ${tests[testIndexMap.status].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.status].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.status].uspto}</span>
                                    </div>
                                    <div className={`integrity__block-item ${tests[testIndexMap.intlClasses].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.intlClasses].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.intlClasses].uspto}</span>
                                    </div>
                                    <div className={`integrity__block-item ${tests[testIndexMap.intlClassesStatus].failed && 'integrity__block-item--mismatch'}`}>
                                        <span className="integrity__block-label">{tests[testIndexMap.intlClassesStatus].name}</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.intlClassesStatus].uspto}</span>
                                    </div>
                                    <div className={`integrity__block-item`}>
                                        <span className="integrity__block-label">Status Date</span>
                                        <span className="integrity__block-value">{tests[testIndexMap.intlClassesStatus].usptoStatusDate}</span>
                                    </div>
                                    <div className="integrity__block-item pt-3">
                                        <a style={{fontSize: 12}} target="_blank" href={`http://tsdr.uspto.gov/#caseNumber=${
                                            serialNumber
                                        }&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch`}>View Trademark on USPTO <img src="svg/external-link.svg" width="10" height="10" alt="external link"/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {failMessages?.length > 0 && 
                        <div className="integrity__failed-messages">
                            {failMessages.map(message => <pre>{message}</pre>)}
                        </div>
                    } 
                    {/* <ul className="custom-list custom-list--checks mb-4">
                        {tests.map(message => {
                                return <li><span>{message.split(':')[0]}</span>: <span style={{color: 'green'}}>{message.split(':')[1]}</span></li>
                        })}
                    </ul> */}
                </>
                }
                {/* {tests.length > 0 && <>
                    <p style={{color: 'red', fontWeight: '600'}}>Tests Failed:</p>
                    {failMessages.length > 0 ? 
                        <>
                            <ul className="custom-list custom-list--x">
                                {failMessages.map(message => {
                                        return <li><pre style={{display: 'inline'}}>{message}</pre></li>
                                })}
                            </ul> 
                            <p className={note + ' pt-5'}>
                                NOTE: In some situation tests fail due to reasons that are not of concern such as having the data in different formats. That is because 
                                the USPTO API does not give access directly to their database but rather to their html file which we in turn have to process and extract data from. 
                                For that reason when a failure happens we provide a raw view of our data compare to the government data in order to compare yourself.
                            </p>
                        </>
                        : <p>No Failing Tests</p>
                    }
                    <div className="pt-3">
                        <a target="_blank" href={`http://tsdr.uspto.gov/#caseNumber=${
                                        serialNumber
                                    }&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch`}>View Trademark on USPTO <img src="svg/external-link.svg" width="15" height="15" alt="external link"/></a>
                    </div>
                </>
                } */}
                {noResults && <>
                    <p className="lead pt-4 integrity_validation">No Relevant Trademarks Found. Reasons this could happen:</p>
                    <ul className="custom-list custom-list--bullets">
                        <li className="lead">Serial number is not in the correct format.</li>
                        <li className="lead">Trademark does not have drawing code of 1000 or 4000.</li>
                    </ul>
                </>}
                {inputError && <>
                    <p className="lead pt-4 integrity_validation">{inputError}</p>
                </>}
            </div>
            {/* <div>
                <h3>Weekly Test Results</h3>
                {weeklyResults && weeklyResults?.map(result => {
                    return (
                        <div></div>
                    )
                })}
            </div> */}
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-6 mb-3">
                        <div className="px-2">
                            <NumberBlock number={getTotalTestsCount()} text="Tests Last 30 Days" /> 
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="px-2">
                            <NumberBlock number={totalTests} text="Total Tests" /> 
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        <p className={note}>
                            NOTE: MerchProtect was initially used internally to track our own products. Using this testing tool allowed us to perfect our data and insure its 
                            integrity. For that reason its very rare that there is a mismatch but if there is its almost always a difference in formatting the same data 
                             or sometimes USPTO changes the structure of their web page and we have to update our parser. That happens because the USPTO API we use for testing doesn't return raw data but rather an html page that we have to parse 
                            and extract the data from. In both cases, however, there is no harm.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <h3 className='h5' style={{color: '#000000ab'}}>Daily Integrity Tests Log</h3>
                <span className="">Below is a log of our daily bulk integrity test results for the last 30 days.</span>
            </div>
            {integrityTests.length > 0 && !integrityTestsLoading && <div id="integrity-table" className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th className="" style={{}}>Status</th>
                            <th className="" style={{}}>Type <QuestionMark width="200px" message="Test Types <br><br> UPDATES: Daily updated trademarks tests.<br> RANDOM: general randomized tests." /></th>
                            <th className="" style={{}}>Date</th>
                            <th className="" style={{}}>Tests Count</th>
                            <th className="" style={{}}>Serials Tested</th>
                            <th className="" style={{width: '250px'}}>Failed Tests</th>
                        </tr>
                    </thead>
                    <tbody>
                        {integrityTests.sort(function(a,b){
                            return new Date(b.date) - new Date(a.date);
                        }).map((test, i) => {
                            return <tr style={{maxWidth: 250}}>
                                {
                                test.fail ? 
                                    <td style={{color: 'red'}}>ISSUE</td> 
                                    : <td style={{color: "green", fontWeight: 700}}>GOOD</td>
                                }
                                <td>{test.test_type || 'RANDOM'}</td>
                                <td>{new Date(test.timestamp).toLocaleDateString()}</td>
                                <td>{test.tests_count}</td>
                                <td> <a href="#" style={{textDecoration: "underline"}} onClick={() => downloadSerialsTested(i)}>Trademarks Tested</a></td>
                                <td>
                                    {test.failed_tests || 'NONE' }
                                    {test.failed_tests && <p className="mt-1" style={{fontSize: 14, color: '#9c2424'}}>{test.reason}</p>}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>}
        </div>
    )
}