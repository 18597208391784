import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import ExpandableTab from './expandableTab';

import {container, section, title, content, image, left, middle, imageCaption, exclusive,
    paragraph, flexRow, example, text, code, fadeIn, paragraphFull, highlighted,
    note } from '../../styles/info/info.module.scss';
import { layout_limited } from '../../styles/layout.module.scss';
import { Link } from 'react-router-dom';

export default () => {
    let [] = useState(0);
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    return (
    <div className={layout_limited}>
        <div className={container}>
            <div className={section}>
                <ScrollAnimation animateIn={fadeIn} duration={0.8}>
                    <h2 className={title}>Why MerchProtect?</h2>
                </ScrollAnimation>
                <div className={flexRow}>
                    <p className={paragraph} style={{fontFamily: 'helvetica, sans-serif'}}>
                        As Merch by Amazon sellers ourselves, we understand how important it is to insure that your merch items are trademark safe on Amazon and other platforms. 
                        But why create a new trademark search engine and tracking tool?  
                        Apart from the fact that searching for and monitoring trademarks on the USPTO site is not so fun, 
                        there are a few strong reasons that prompted us to create Merch Protect. Click on any of the tabs below to expand and read more about them. 
                        You can also check the <Link to="/introduction">introduction blog</Link> or our <a target="_blank" href="https://youtu.be/mgHpBaYugLk">introduction video</a>.
                    </p>
                </div>
                <br/>
                <div className={content + ' ' + text}>
                    <div className={flexRow}>
                            <ExpandableTab title="Which Keywords to Track?" >
                                    <div className={text + ' pt-2'} >
                                        {/* <p className={text}>
                                        Many merch sellers 
                                        wonder why their Merch was rejected on Amazon only to realize they have missed looking up or tracking common phrases or simply 
                                        tracked their keywords in a slightly different format than the trademark.
                                        </p> */}
                                        <p className={text}>
                                        Using a traditional keyword tracking system where you need to pick your keywords is not the best solution when dealing with amazon. That is 
                                        because they have very sophisticated algorithms that will break down your phrases into all possible combinations, some of which you might have 
                                        not even considered. Guessing which combination of keywords to track is time consuming and common keywords are easy to overlook which can be dangerous. 
                                        </p>

                                        <p className={text}>
                                        For instance, you might track <span className={example}>GOTTA RUN NOW</span> and assume it's safe. 
                                        There is no exact match and it is not "contained" by any trademark. But then 
                                        it turns out "GOTTA RUN" is trademarked.
                                        GOTTA RUN is only one of 6 adjacent keyword combinations and many more
                                        derived english versions in this three words phrase alone! 
                                        </p>
                                        {/* <p className={text}>
                                        Imagine if your title and description have over 20 words. It will be difficult to cover and track all possibilities. 
                                        </p> */}
                                        <p className={text}>
                                        With our tracking tool, there is no need to guess the keywords. You only need to track your whole product details with one click using our extension  
                                        and we've got you covered.
                                        </p>
                                        Our search algorithm 
                                        will lookup and track every possible combination of adjacent words and their english derivatives following a very reliable search criteria 
                                        described in the criteria section on this page. We will also show you how your merch keywords were split (which can be a long list). 
                                    </div>
                                    <div className={`${image} ${middle}`}>
                                            <img src="/images/main_full.png" alt="Trademark Tracking Tool"></img>
                                    </div>
                                    <p className={imageCaption}>MerchProtect Dashboard</p>
                                    <br/>
                                    <br/>
                            </ExpandableTab>     
                    </div>
                    <div className={flexRow}>
                        <ExpandableTab title="English Is Not So Simple">
                                <div className={text + ' pt-2'}>
                                    <p className={text}>
                                        Trademark laws look at whether phrases are similar enough to cause confusion and Amazon knows that. That is why we have multiple features that help detect similar 
                                        matches and one of them is our stemmed match search option. Because english can be complicated in some cases, this feature will 
                                        stem each keyword to its root english version and compare the roots. This in turn will provide better protection.
                                    </p>
                                    {/* <p className={text}>
                                        Other trademark tools will lookup exact and partial matches. This is fine in some cases but 
                                        with amazon you have to be extra careful given their smart algorithms. Your phrase might not exactly or partially match the trademark 
                                        but rather a derived english version of it. This is where our search engine shines. 
                                    </p> */}
                                    <p className={text}>
                                        To demonstrate this, lets try looking up the phrase <span className={example}>love living</span>. 
                                        Try it with your favorite tools,
                                        they will all miss the trademarked phrase <span className={example}>loving living</span>. That is because 
                                        "love" and "loving" do 
                                        not partially/exactly match; "love" contains the letter "e" at the end which is not in "loving". Though in this case the phrase is common and will probably not cause trouble, 
                                        you can imagine the implications it could have on your Amazon account in other cases. 
                                    </p>
                                </div>
                                <div className={`${image} ${middle}`}>
                                    <img src="/images/loveliving.png"></img>
                                </div>
                                <br/>
                        </ExpandableTab>
                    </div>
                    <div className={flexRow}>
                        <ExpandableTab title="Sounds Similar?">
                            <p className={text + ' pt-2'}>
                                Trademark laws don't just look at whether your phrase matches someone else's. What matters is 
                                whether it is similar enough to cause confusion. This is why we developed the following two features: sound and similarity matching.
                            </p>
                            <p className={text}>
                                <b className="font-weight-bold">Sound Matching</b> is very helpful when a trademarked phrase doesn't use the correct english spelling for a word or perhaps combined two words 
                                into one such as <span className={example}>BullDog</span>. This will not be caught with a traditional trademark search for "Bull Dog" but it will with our sound search. 
                                It is also helpful when there is a play on words. 
                            </p>
                            <p className={text}>
                                Lets take for instance <span className={example}>Girl Dad</span>. A traditional search will not show "GIRLDAD" or "GIRLDDAD" matches. Unfortunately, many Merch sellers got a strike for this and so you can see how dangerous this can be. 
                            </p>
                            <div className={`${image} ${middle}`}>
                                <img src="/images/soundmatch2.png" alt="Trademark similar Sound matching"></img>
                            </div>
                            <br/>
                            <p className={note + ' ' + text}>Note: Sound matching is not bullet proof. It is only meant to assist in your protection and it will only be helpful with shorter keywords and not full sentences. </p>
                            <p className={text}>
                                <b className="font-weight-bold">Similarity matching</b> allows you to set a level  of similarity 
                                at which you will be alerted (via email) if a new trademark exceeds that level. Similarity here is in terms of how many letters off your combinations are from new trademarks. 
                                This allows you to take measures before it's too late. It will also 
                                save you lots of time when trying to figure out if any of the newly filed or revived trademarks are similar to yours, especially that there could be thousands of 
                                new trademarks filed each month. 
                            </p>
                            <p className={text}>
                                Similarity matching is only available with our tracking tool and is temporarly available under our free subscription plan. Check the screenshot in the 
                                section below for an example.
                            </p>
                            <br/>
                        </ExpandableTab>
                    </div>
                    <div className={flexRow}>
                        <ExpandableTab title="Stay Up To Date">
                            <p className={text + ' pt-2'}>
                                Ever wonder what was filed in the last few weeks? Even better, would you like to know if any of your own Merch contains a keyword that is similar to any of 
                                the newly filed trademarks without going through them one at a time? Or maybe you want to see what has gone into the opposition stage in order to file an opposition letter. 
                                In any case, our tool makes all that available and easy to navigate.
                            </p>
                            <p className={text}>
                                The "updates" section on our dashboard allows you to view new trademarks and sort/filter them based on multiple criteria. 
                                One of the interesting sorting options is to sort based on similarity to your own tracked merch items. If a trademarks exceeds the percentage of similarity you chose,  
                                it will be heighlighted and it will show you exactly which keyword it is similar to. see image below.
                            </p>
                            <div className={`${image} ${middle}`}>
                                <img src="/images/similarity_filter.png" alt="Trademark similar Sound matching"></img>
                            </div><br/><br/>
                            <p className={text}>
                                There are a few types of trademark updates that you should be aware of and we took them all into account. These are: 
                            </p>
                            <p className={text}>
                                <span className="font-weight-bold mb-1">Newly Filed</span>: Shows brand new trademarks that were just filed.   
                            </p>
                            <p className={text}>
                                <span className="font-weight-bold mb-1">Newly Registered</span>: Shows existing trademarks that were just registered.  
                            </p>

                            <div className={text + ' ' + highlighted + ' ' + exclusive}>
                                <span className="font-weight-bold mb-1">Revived</span>: This one is special and hard to get on your own. It shows trademarks that had a 
                                    DEAD status but now turned to LIVE. those are easy to miss and can cause you trouble.    
                            </div>
                            <p className={text}>
                                <span className="font-weight-bold mb-1">Opposition</span>: Trademarks that are in the opposition stage. This list is specially handy 
                                    when sorted based on similarity percentage to your own Merch.  It will allow you to take action and stop the trademark from going forward before its too late.
                            </p>
                            <div className={text + ' ' + highlighted + ' ' + exclusive}>
                                <p className={text}>
                                    <span className="font-weight-bold mb-1">Could be DEAD soon</span>: Trademarks that have a status indicating they are about to be DEAD. This is one of our favorites because 
                                    it will give you an opportunity to be one of the first to know about them once they are dead. As you know many Amazon sellers have been avoiding those so you will have a lot less 
                                    competition when you use them in your products. <br/><br/>
                                    A trademark could be going dead for multiple reasons, for instance the examiner has refused it more than one time or there was a petition to cancel and it succeeded. <br/>
                                    <span className={note}>(Note: You need to at least have the STANDARD plan to see this list)</span>
                                </p>
                                <p className={text}>
                                    <span className="font-weight-bold mb-1">Recently Dead </span>(coming soon): Trademarks that have recently gone DEAD. This is helpful to find opportunities.
                                </p>
                            </div>
                            <div className={`${image} ${middle} w-100`}>
                                <img src="/images/newmarks.png" alt="Newly Filed Trademarks"></img>
                            </div>
                            <p className={imageCaption}>New Trademarks Section</p>
                        </ExpandableTab>
                    </div>
                    <div className={flexRow}>
                        <ExpandableTab title="Can I Trust Your Data?">
                            <p className={text + ' pt-2'}>
                                In order to truly be protected, our database has to match what is in the Uspto database. With other tools, we were only wishing the data 
                                is accurate and in some cases it was either outdated or inaccurate.
                            </p>
                            <p className={text}> This is no longer the case. We take the accuracy and integrity of our trademark database very seriously. In order to test and prove this, we developed a tool
                                that compares thousands of trademarks from our database against USPTO webpage (from their API) on a daily basis and we publish the results.
                            </p>
                            <p>
                                Our Database is updated on daily basis. Each morning we pull data from the USPTO for the date that is two days prior. So if today is 01/03/2020, our database will have 
                                the latest from 01/01/2020.
                            </p>
                            <p className={text}>
                                There is two types of testing we perform and they work as follows: 
                            </p>
                            <p className="font-weight-bold">1. Daily Random Tests</p>
                            <ul className={text} style={{
                                    color: 'rgba(0, 0, 0, 0.655)', 
                                    paddingLeft: '20px', 
                                    marginBottom: '0.75rem'
                                }}>
                                <li>* Generate 500 random serial numbers</li>
                                <li>* Retrieve trademark information for these trademarks from our database</li>
                                <li>* Retrieve trademark information for these trademarks from USPTO API</li>
                                <li>* Compare our data against theirs and if any part fails we create an alert to review</li>
                            </ul>
                            <p className="font-weight-bold">2. Daily Updates Tests</p>
                            <ul className={text} style={{
                                    color: 'rgba(0, 0, 0, 0.655)', 
                                    paddingLeft: '20px', 
                                    marginBottom: '0.75rem'
                                }}>
                                <li>
                                * This tests our daily updater and insures its accuracy. this is a very similar process to the randomized one above 
                                except it test Trademarks that were just updated on that given day.
                                </li>
                            </ul>
                            <p className={text}>
                                This process is run internally on a daily basis for thousands of trademarks. In order to be transparent we publish 
                                our results and give you the power to run tests for yourself. You will be able to insert a trademark 
                                serial number and see our data side by side with USPTO data (as is on their web page) to compare.
                            </p>
                            <Link to="/integrity">Click here to see our results and run your own tests</Link>
                        </ExpandableTab>
                    </div>
                    <br/>
                    <div className={flexRow}>
                        <div className="text-left w-100">
                            <h2 className={title} id="search_criteria">BROWSER EXTENSION</h2>
                        </div>
                        <div className={`${paragraph} ${paragraphFull}`}>
                            <p className={text}>
                                Our tracking tool offers you unlimited access to our <a style={{textDecoration: "underlined"}} href="https://chrome.google.com/webstore/detail/trademark-search/akfghebhfgjekjfdnkmjmmcgogicijgk">Chrome extension</a> that will make it very convenient to track your products with one click and also 
                                helps you detect trademarks on the product page even if you don't want to track it. Here is a list of the benefits you get 
                                from our extension. 
                            </p>    
                        </div>
                        <ExpandableTab title="Extension Benefits">
                            <ul className={text + ' custom-list custom-list--bullets custom-list--neutral'}>
                                <li>Detect trademarks on the product page on amazon.</li>
                                <li>Detect trademarks on the spot before submitting your product for review.</li>
                                <li>Track and untrack products with one click.</li>
                                <li>Trademarks detected will be highlighted based on severity. Check the screenshot below.</li>
                            </ul>
                            <div className={`${image} ${left} w-100`}>
                                    <img src="/images/extension_info.png" alt="Merch Protect Chrome Extension"></img>
                                </div>
                            <p className={imageCaption}>Merch Protect Chrome Extension</p>
                        </ExpandableTab>
                    </div>
                    <br/>
                    <div className={flexRow}>
                        <div className="text-left w-100">
                            <h2 className={title} id="search_criteria">SEARCH CRITERIA</h2>
                        </div>
                        <div className={`${paragraph} ${paragraphFull}`}>
                            <p className={text}>Our lookups follow a very reliable search criteria for merch. If you had searched 
                            for trademarks on the USPTO website using something like this: </p>
                            <p className={`${text} ${code}`}>("4"[MD] OR "1"[MD]) AND "sushi freak"[MI] AND (("shirt"[GS] AND "035"[IC]) OR "025"[IC]) AND (LIVE)[LD]</p>
                            <p className={text}>Our search engine follows a very similar yet enhanced criteria. If this is not familiar to you, it basically means the following:</p>
                            <p className={text}>Look up LIVE trademarks that are under the international class 025 (clothing) that have a drawing code of 4000 or 1000. But in case a
                            trademark falls under class 035 (marketing) instead of 025 then show them only if they contain a clothing term in their statement. 
                            </p>
                            <p className={text}>Note that in our actual implementation we look for all clothing terms and not just shirts.</p>
                            <br/>
                        </div>
                        <ExpandableTab title="Search Types">
                            <p className={text}>There are two search types: EXACT and STEMMED.</p>
                            <p className={text}>
                            <span className="font-weight-bold d-inline-block mb-1">Exact</span><br/> Finds exact matches to the provided phrase or any of its split keyword combinations 
                            if "split" option is selected. Note that EXACT search will ignore singular/plural rules. This is a good option in two cases: <br/><br/>
                                - CASE 1: When you want to get the results as quickly as possible on our chrome extension. Exact type is much faster than STEMMED. <br/>
                                - CASE 2: when you manually want to search terms that were noted as ignored by our split options for being too common (see ignored section below). 
                            </p>
                            <p className={text}>
                            <span className="font-weight-bold d-inline-block mb-1">STEMMED</span><br/> will find matches that share the same root or exactly match one of the split combinations of your phrase. 
                            This is the recommended default option because it offers more security.
                            </p>
                            {/* <p className={text}>
                            <span className="font-weight-bold d-inline-block mb-1">Exact (contained)</span><br/> Finds exact matches to your phrase + trademarks that contain your phrase exact phrase.
                            </p> */}
                            {/* <p className={text + ' pb-1'}>
                            <span className="font-weight-bold d-inline-block mb-1">Split Contained</span><br/> Returns the Split results
                            + trademarks that are longer than your sentence but contain it fully. 
                            </p> */}
                                                    <br/>
                        </ExpandableTab>
                        <ExpandableTab title="Search Options">
                            <p className={text}>
                                After you select your search type, you can fine tune your search using our search options below:
                            </p> 
                            <p className={text}>
                                <span className="font-weight-bold d-inline-block mb-1">CONTAINED OPTIONS</span><br/>
                                This is a drop down menu with the following options: <br/>
                                <span className="d-inline-block ml-2">
                                    <span className="font-weight-bold d-inline-block" style={{fontSize: 14}}>NOT CONTAINED:</span> Ignores matches that are longer that your phrase that contain it. <br/>
                                    <span className="font-weight-bold d-inline-block" style={{fontSize: 14}}>CONTAINED:</span> Includes trademarks that are longer than your phrase and contain it. <br/>
                                    <span className="font-weight-bold d-inline-block" style={{fontSize: 14}}>CONTAINED (each):</span> A special option that return any trademark that contain any of the split 
                                    keyword combinations in your phrase. This might return many results but is useful when extreme caution is necessary. When this is selected, Split option 
                                    is checked by default and can't be unchecked. <br/>
                                </span>
                            </p> 
                            <div className={text + ' ' + highlighted + ' ' + exclusive}>
                                <p className={text}>
                                    Its important to emphasis here that unlike the contained search provided by other tools, our CONTAINED (each) search option will lookup contained matches for all the split keyword combinations in your phrase.
                                </p>
                                <p className={text}>
                                    To explain this option its a good idea to demonstrate with an example, try looking up <span className={example}>kellie loves living</span> with our tool (using CONTAINED (each) option) and compare the results to other tools using their contained search. 
                                    You will notice that ours returned several results while the other tool found none.
                                </p>
                                <p className={text}>
                                    Why? because we didn't just look up trademarks that contain "kellie loves living" but also trademarks that contain "loves living", "kellie loves" and "kellie". 
                                    Remember that we also look up derived english variations which is why we matched "Kelly" for "Kellie".  
                                </p>
                                <p className={text}>
                                    This is a good option if you are trying to be extremely careful in making sure there is not confusion between your text 
                                    and any trademark. It is also good for smaller phrases.
                                </p>
                            </div>
                            <p className={text}>
                                <span className="font-weight-bold d-inline-block mb-1">SPLIT</span><br/>
                                If Selected, your phrase will be split into all possible combinations. This is recommended and checked by default.<br/>
                            </p> 
                            <p className={text}>
                                <span className="font-weight-bold d-inline-block mb-1">SOUND</span><br/>
                                If Selected, your phrase will be looked up for similar sounding trademarks.<br/>
                                <span className={note}>NOTE: Split options does not apply to sound lookups. Your whole phrase will be used.</span>
                            </p> 
                            <p className={text}>
                                <span className="font-weight-bold d-inline-block mb-1">DEAD</span><br/>
                                If Selected, our search engine will also return dead trademarks.<br/>
                            </p> 
                            
                        </ExpandableTab>
                        <ExpandableTab title="Ignored words">
                            <p className={text}>In order to avoid lots of clutter, there is two lists of words we utilize. However, we still provide you with a breakdown of 
                                exactly what was looked up and what was ignored. Also, you can always explicitly lookup those ignored words using our exact match option. To see the break 
                                down click on the underlined sentence under the search box. See the following screenshot:
                            </p> 
                            <div className={`${image} ${middle}`}>
                                    <img src="/images/ignored.png" alt="Trademark Tracking Tool"></img>
                                </div>
                                <br/><br/>
                            <p className={text}>The two lists are: <a href={process.env.NODE_ENV !== 'production' ? 'http://localhost:3002/stopwords' : "https://api.merchprotect.com/stopwords"} target="_blank">STOP WORDS</a>{" "}
                                and <a href={process.env.NODE_ENV !== 'production' ? 'http://localhost:3002/commoncombs' : "https://api.merchprotect.com/commoncombs"} target="_blank">COMMON COMBINATIONS</a>. Stop words is a small list of english stop words such as "is", "the", "in", etc.. Common combinations list is{" "} 
                                comprised of a handful of the most common two word combination such as "is the", "if you", "to you".</p>

                            {/* <p className={text}>The <a href={process.env.NODE_ENV !== 'production' ? 'http://localhost:3002/commonwords' : "https://api.merchprotect.com/commonwords"} target="_blank"> second list </a> comprised of the most commonly used english words. We only ignore these words if they are in a full sentence. 
                            However, if they are by themselves such as <span className={example}>war t-shirt</span>, they will still be looked up.</p>
                            <p className={text}>Try looking up <span className={example}>war and love tshirt</span> and also lookup 
                            <span className={example}>war t-shirt</span> to see it in action. The first full sentence will not return war but the second 
                            will return it despite being on the common words ignore list; that is because it is by itself.</p>
                            <p className={text}>If a word is not in our list of commonly used english words such as <span className={example}>keto</span>, then it 
                                is looked up despite being in a full sentence.</p> */}
                            <p className={text}>any other words not included in those two lists is looked up despite where it is.</p>
                        </ExpandableTab>
                    </div>
                    <br/>
                    {/* <p className={title}>Alerts</p> */}
                    <div className={flexRow}>
                        <div className="text-left w-100">
                            <h2 className={title} id="search_criteria">EMAIL ALERTS</h2>
                        </div>
                        <div className={flexRow}>
                            {/* <div className={`${image} ${left}`}>
                                <img src="/images/alert.jpg"></img>
                            </div> */}
                            <div className={paragraph} style={{ width: '100%'}}>
                                <p className={text}>When you track a merch product, it will be checked against USPTO trademark updates on a daily basis and an alert email is sent out twice a week; Monday and Thursday.</p>
                                <p className={text}>If any of your products matches one of the trademark categories mentioned below, you will receive an email notification with all the necessary 
                                    information.
                                </p>
                                <p className={text} style={{marginBottom: '5px'}}>The types of trademark updates we include in our emails are:</p>
                                <ul className={text + ' custom-list custom-list--bullets custom-list--neutral'}>
                                    <li>New Applications</li>
                                    <li>New Registrations</li>
                                    <li>Revived Trademarks</li>
                                    <li>Similar Trademarks</li>
                                </ul>
                                <div className={`${image}`}>
                                        <img src="/images/email.png" alt="Trademark Tracking Tool"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}