import React from "react";
import {
    planWrapper,
    top_section,
    list,
    title,
    price,
    featureInclusion,
    subscribeBtn,
    subscribe,
    exclMark,
    inactive,
    locked,
    limited,
    planWrapperHighlighted,
    subscribedBtn,
    subscribeGreen
} from "../../styles/plans/plans.module.scss";
import { Link } from "react-router-dom";
import { userPlanEnums } from "../../util/constants";

export default ({ plan, userPlan, signedin }) => (
    <div className={`${planWrapper} ${['BASIC'].includes(plan.plan_id) && userPlanEnums[userPlan] < userPlanEnums[plan.plan_id] ? planWrapperHighlighted : ''}`} data-testid="plan">
        <div className={`${top_section} ${!plan.active ? inactive : ''}`}>
            <h3 className={title}>{plan.plan_id}</h3>
            <p className={price}>${['FREE', 'BASIC', 'STANDARD'].includes(plan.plan_id) ? plan.price : '... '}/month </p>
            {['BASIC', 'STANDARD'].includes(plan.plan_id) && <span className={limited}>{Math.floor(plan.trial_weeks / 4)} MONTHS TRIAL</span>}
        </div>
        <ul className={`${list} ${!plan.active ? inactive : ''}`}>
            <li>
                Merch Limit
                <div className={exclMark}>
                    <p>The number of merch products you can track</p>
                </div>
                <span className={featureInclusion}>{plan.merch_limit}</span>
            </li>
            <li>
                New Trademarks
                <div className={exclMark}>
                    {plan.plan_id === "FREE" ? <p>Limited access to the lists of daily filed trademarks; Excluded are "Revived trademarks", "Recently Dead" and "Soon To Be Dead" lists.</p>
                        : <p>Unlimited access to daily updated lists of trademark updates such as Newly Filed Trademarks.</p>
                    }
                </div>
                <span className={featureInclusion}>{plan.plan_id === "FREE" ? "LIMITED" : "YES"}</span>
            </li>
            <li>
                Extension
                <div className={exclMark}>
                    <p>
                        Access to our chrome extension.
                    </p>
                </div>
                <span className={featureInclusion}>{plan.plan_id === "FREE" ? "YES" : "YES"}</span>
            </li>
            <li>
                Emailed Alerts
                <div className={exclMark}>
                    <p>Email notification for any new matching trademarks.</p>
                </div>
                <span className={featureInclusion}>{plan.plan_id === "FREE" ? "NO" : "YES"}</span>
            </li>
        </ul>
        <div className={`${subscribe} ${showGreenBtn(plan.plan_id, userPlan, signedin) ? subscribeGreen : ''} ${!plan.active ? inactive : ''}`}>
            {plan.plan_id === "FREE" && !signedin ? (
                <Link to={{ pathname: "/login", state: { signup: true } }}>
                    <p className={subscribeBtn}>SIGNUP</p>
                </Link>
            ) : plan.plan_id === "FREE" && signedin ? (
                <p className={subscribeBtn}>&#10003; SIGNED UP</p>
            )
            : !userPlan || userPlanEnums[userPlan] < userPlanEnums[plan.plan_id] ? (
                <Link
                    to={{
                        pathname: `/subscribe`,
                        search: `?plan=${plan.plan_id}`,
                        state: {
                            plan: plan.plan_id,
                            price: "$" + plan.price
                        }
                    }}
                >
                    <p className={subscribeBtn}>SUBSCRIBE</p>
                </Link>
            ) 
            : <p className={subscribeBtn}>&#10003; SUBSCRIBED</p>}
        </div>
        {!plan.active && <div className={locked}>
            <i className="fas fa-lock fa-2x" style={{color: '#000000ab'}}></i>
            <p className="lead">Coming soon</p>
        </div>}
    </div>
);

function showGreenBtn(plan_id, userPlan, signedin) {
    return (userPlanEnums[userPlan] >= userPlanEnums[plan_id]) || (plan_id === 'FREE' && signedin) ? true : false;
}