export const SIGNIN = "SIGNIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const ADD_NEWMERCH = "ADD_NEWMERCH";
export const MERCH_SUBMIT_ERROR = "MERCH_SUBMIT_ERROR";
export const NEWMERCH_LOADING = "NEWMERCH_LOADING";
export const MERCH_LOADING = "MERCH_LOADING";
export const TRACK = "TRACK";
export const PRODUCT_TRACKING_ERROR = "PRODUCT_TRACKING_ERROR";
export const UNTRACK = "UNTRACK";
export const FETCH_MERCH_ERROR = "FETCH_MERCH_ERROR";
export const DELETE_MERCH = "DELETE_MERCH";
export const TRACK_LOADING = "TRACK_LOADING";
export const IGNORE_MARK = "IGNORE_MARK";
export const TRACK_MARK = "TRACK_MARK";
export const POPULATE_MERCH = "POPULATE_MERCH";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_LOADING = "UPDATE_ACCOUNT_LOADING";
export const UPDATE_ACCOUNT_ERROR = "UPDATE_ACCOUNT_ERROR";
export const RESET_UPDATE_SUCCESS_FLAG = "RESET_UPDATE_SUCCESS_FLAG";
export const SAVE_IGNOREWORDS_LOADING = "SAVE_IGNOREWORDS_LOADING";
export const SAVE_IGNOREWORDS_SUCCESS = "SAVE_IGNOREWORDS_SUCCESS";
export const SAVE_IGNOREWORDS_ERROR = "SAVE_IGNOREWORDS_ERROR";
export const SAVE_IGNOREPHRASES_LOADING = "SAVE_IGNOREPHRASES_LOADING";
export const SAVE_IGNOREPHRASES_SUCCESS = "SAVE_IGNOREPHRASES_SUCCESS";
export const SAVE_IGNOREPHRASES_ERROR = "SAVE_IGNOREPHRASES_ERROR";
export const UPDATE_WEEKLY_NEWTRADEMARKS = "UPDATE_WEEKLY_NEWTRADEMARKS";
export const SET_MERCH_WITH_COMBS = "SET_MERCH_WITH_COMBS";
export const SIGNIN_LOADING = "SIGNIN_LOADING";
