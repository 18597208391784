import React from "react";
import { errorBar, errTitle, errMessage, sorry } from "../styles/main/errorBar.module.scss";

export default ({ title, message }) => (
    <div className={errorBar}>
        <p className={sorry}>Sorry!</p>
        <h2 className={errTitle}>{title}:</h2>
        <p className={errMessage}>{message}</p>
    </div>
);
