import React, { useState } from 'react';

import './markRow.scss'
import { codesStatusMap, codes } from '@merchprotect/trademark-status-codes';
import ProgressBar from '../progressBar/ProgressBar';

export default function MarkRow({mark, hideSimilarMerch, largeCards, style, defaultToOpen }) {
    if (!style) style = {};
    const [open, setOpen] = useState(defaultToOpen || false);

    let intlClassesString = '';
    let usClassesString = '';

    let intlClasses = mark.intlClasses ? mark.intlClasses : mark.intl_classes;
    intlClasses && intlClasses.forEach(intlClass => {
        if(typeof intlClass === 'object') {
            if (!intlClassesString) {
                intlClassesString = intlClass.code;
            } else {
                intlClassesString+= ', ' + intlClass.code;
            }
        } else {
            if (!intlClassesString) {
                intlClassesString = intlClass;
            } else {
                intlClassesString+= ', ' + intlClass;
            }
        }
    });
    mark.usClasses && mark.usClasses.forEach(usClass => {
        if (!usClassesString) {
            usClassesString = usClass.code;
        } else {
            usClassesString+= ', ' + usClass.code;
        }
    });

    function highlighClothingTerms(string) {
        let clothingTerms = [' cloth', ' apparel', 'shirt', 'sweater', 'top', 'hoodie', 'jersey', ' hat', ' caps', ' dress', 'jacket', 'coat', ' short', ' pant'];
        let html = '' + string;
        for (let i = 0; i < clothingTerms.length; i++) {
            let term = clothingTerms[i]
            html = html.replace(new RegExp(term, 'ig'), `<i style="font-weight: 700">${term}</i>`)
        }

        return html;
    }

    let markStatus = codesStatusMap[mark.cfh_status_cd] ? (/live|new|opposition/ig.test(codesStatusMap[mark.cfh_status_cd]) ? 'LIVE' : 'DEAD') : null;

    return (
        <div key={mark.serial_no} className="row-container  py-2 px-2" style={{ ...style,
                backgroundColor: mark.matchingMerch && mark.matchingMerch.length ? '#ffffe7c7' : open ? 'rgb(255 244 228)' : 'white', 
        }} >
            <div onClick={() => setOpen(!open)} className={`row__wrapper d-flex` + ` ${largeCards ? 'py-lg-2' : ''}`} style={{ padding: open ? 5 : 0,}}>
                {/* <i className="fas fa-caret-right mr-1"></i> */}
                <div className="row__column mark__title">{mark.mark_id_char} {mark.registration_dt && <i class="far fa-registered fa-sm registered-icon registered-icon--danger"></i>}</div>
                <div className="row__column mark__status font-weight-bold" title={mark.cfh_status_cd}><span className="row__label row__label--mobile">Status {" "}</span>
                    <span style={{ color: markStatus === 'LIVE' ? 'rgba(77, 146, 50, 0.808)' : markStatus === 'DEAD' ? '#ea3e3ee8' : 'inherit',
                        fontWeight: 700,
                        fontSize: 12
                    }}>
                       {" "} {codesStatusMap[mark.cfh_status_cd] ? getSimpleStatus(mark.cfh_status_cd)  : mark.cfh_status_cd}
                    </span>
                </div>
                <div className="row__column mark__filing"><span className="row__label">Reg</span> {mark.registration_dt && new Date(mark.registration_dt).toLocaleDateString() !== 'Invalid Date' ? new Date(mark.registration_dt).toLocaleDateString() : 'n/a'}</div>
                <div className="row__column mark__regist"><span className="row__label">Filed</span> {mark.filing_dt ? new Date(mark.filing_dt).toLocaleDateString() :  'N/A'}</div>
            </div>
            {open && <div className="sub-row__container">
                    <div className="sub-row__wrapper d-flex align-items-center flex-wrap py-3 px-3">
                        <div className="sub-row__column"><span className="sub-row__label">Serial No.</span> 
                            {" "}<a
                                // className={}
                                target="_blank"
                                href={`http://tsdr.uspto.gov/#caseNumber=${
                                    mark.serial_no
                                }&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch`}
                            >
                            {mark.serial_no}
                            </a>
                        </div>
                        {/* <div className="sub-row__column"><span className="sub-row__label">Status:</span> {codesStatusMap[mark.cfh_status_cd] || mark.cfh_status_cd}</div> */}
                        <div className="sub-row__column"><span className="sub-row__label">Draw Code</span> {mark.mark_draw_cd || 'n/a'}</div>
                        <div className="sub-row__column"><span className="sub-row__label">Int. Classes</span> {intlClassesString || 'n/a'}</div>
                        <div className="sub-row__column"><span className="sub-row__label">US Classes</span> {usClassesString || 'n/a'}</div>
                        <div className="sub-row__column"><span className="sub-row__label">Owner</span> {mark.party_name || 'n/a'}</div>
                        {/* <div className="sub-row__column"><span className="sub-row__label">Status Code</span> {mark.cfh_status_cd ? `(${mark.cfh_status_cd}) ` + codesStatusMap[mark.cfh_status_cd] : 'n/a'}</div> */}
                        <div className="sub-row__column"><span className="sub-row__label">Last Updated</span> {mark.transaction_dt ? new Date(mark.transaction_dt).toDateString() : 'n/a'}</div>
                        {codes[mark.cfh_status_cd]?.detail ? <div className="sub-row__column w-100"><span className="sub-row__label">Status</span> {mark.cfh_status_cd ? `(${mark.cfh_status_cd}) ` + codesStatusMap[mark.cfh_status_cd] : 'n/a'} / {codes[mark.cfh_status_cd].detail}</div> : ''}
                        {codes[mark.cfh_status_cd]?.stage !== null && <div className="sub-row__column w-100 border-top mt-3">
                                <ProgressBar statusCode={mark.cfh_status_cd} />
                        </div>}
                        {!hideSimilarMerch &&
                            <div className="sub-row__column w-100 border-top mt-1 pt-2"><p className="sub-row__label pb-1">Similar Merch</p> <br/>
                                {
                                    mark.matchingMerch && mark.matchingMerch.length > 0 ? mark.matchingMerch
                                        .sort((a, b) => b.matchingScore - a.matchingScore)
                                        .map(match => 
                                            <a target="__blank" href={`/dashboard/client?search=` + match.merch_title.slice(0, 15)}>
                                                <span key={match.merch_id} className="sub-row__match">[ {match.matchingKeyword.toUpperCase()} ] - {match.merch_title.slice(0, 50)}..</span>
                                            </a>
                                            ) 
                                            : 'N/A'
                                }
                            </div>
                        }
                        <div className="sub-row__column w-100 border-top mt-3 pt-3 d-flex flex-wrap align-items-start">
                            <p className="sub-row__label text-start mb-2" style={{minWidth: 150}}>Goods & Services</p>
                            <div>
                                {mark.stat_text && mark.stat_text.length !== 0 ? mark.stat_text.map((statement, i) => <div className="mb-1" key={statement.slice(0, 10)}>
                                    {/* V2 TODO */}
                                    {/* <span className="font-weight-bold">{statement.code}</span>:&nbsp; */}
                                    <span key={i} dangerouslySetInnerHTML={{__html: highlighClothingTerms(statement)}}></span>
                                </div>) : 'N/A'}
                            </div> 
                        </div>
                        
                    </div>
                </div>}
        </div>
    )
}

function getSimpleStatus(code) { 
    if (!code) return code;

    if (/(REGISTERED)/ig.test(codesStatusMap[code])) {
        return codesStatusMap[code].split("-")[0];
    }

    return codesStatusMap[code]
}