import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from '../../../util/axios'
import urls from '../../../config/urls'

export default class ErrorBoundary extends Component {
    static propTypes = {
        prop: PropTypes
    }
    state = {
        hasError: false,
    }

    componentDidCatch(error, errorInfo) {
        debugger;
        if (process.env.NODE_ENV === 'production') {
            axios.post(urls.API_BASE + '/application-error', {
                message: error.message,
                stack: error.stack,
                path: window.location.pathname,
                source: 'Application root boundary'
            });
        } else {
            console.error(error);
        }
        this.setState({
            hasError: true,
        })
    }

    render() {
        if (!this.state.hasError) {
            return this.props.children;
        } else {
            return (
                <div className="lead" style={{  width: '100%', textAlign: 'center', marginTop: '40vh'}}>
                    Ops, something went wrong. Please try refreshing the page.
                </div>
            )
        }
    }
}
