import React, { Component, createRef } from "react";
import axios from "axios";
import List from "../../sharedComponents/list";
import {
    container,
    info,
    circularProgress,
    notice,
    upgradeWrapper,
    upgradeTxt,
    upgradeBtn,
    disabledBtn,
    receiptWrapper,
    confirmationWrapper,
    confirmationContainer,
    proceedBtn,
    cancelBtn,
    modalWrapper
} from "../../styles/plans/subscribe.module.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

import withLoggedIn from "../../sharedComponents/HOC/loggedinOnly";
import Success from "../../sharedComponents/success";
import Err from "../../sharedComponents/error";
import urls from "../../config/urls";
import { connect } from "react-redux";
import { signin } from "../../redux/actions";
import { LinearProgress } from "@material-ui/core";
import { getParameterByName } from "../../util";

class Subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            token: "",
            error: false,
            disableUpgradeBtn: false,
            receipt: {},
            showConfirmationModal: false,
            modalLoading: false,
            plan: 'fetching...',
            price: 'fetching...',
            alreadySubscribed: false,
        };
        this.dropinRef = createRef();
    }

    componentDidMount() {
        if (this.props.signedin) {
            this.getBraintreeToken();
        }
    }

    // handleClick = () => {
    //     this.setState({
    //         loading: true
    //     });
    // };

    getBraintreeToken() {
        if (!this.props.location.state) this.props.location.state = {}

        const plan = getParameterByName('plan');

        axios.get(urls.API_BASE + `/checkouts/new?plan=${plan}`)
            .then(tokenResponse => {
                console.log(tokenResponse);

                if (tokenResponse.status === 200) {
                    const plan = tokenResponse.data.plan.plan_id;
                    const price = tokenResponse.data.plan.price;
                    
                    if (tokenResponse.data.alreadySubscribed) {
                        return this.setState({ 
                            loading: false, 
                            error: false,
                            alreadySubscribed: true,
                            plan, price 
                        })
                    } 

                    if (!tokenResponse.data.useExistingPayment) {
                        // eslint-disable-next-line no-undef
                        braintree.dropin.create({
                            authorization: tokenResponse.data.clientToken,
                            container: '#dropin-container',
                            paypal: {
                                flow: 'vault'
                            }
                        }, (createErr, instance) => {
                            // let button = this.dropinRef.current.getElementById('#submit-button');
                            if (createErr) {
                                console.error(createErr)
                                this.setState({loading: false, success: false, error: 'Something went wrong, please try again or contact us at support@merchprotect.com' })
                            }
                            instance.on('paymentMethodRequestable', (event) => {
                                console.log(event);
                                instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
                                    // Submit payload.nonce to your server
                                    if (requestPaymentMethodErr) {
                                        console.error(requestPaymentMethodErr)
                                        this.setState({loading: false, success: false, error: 'Something went wrong, please try again or contact us at support@merchprotect.com' })
                                    }
    
                                    this.subscribe(payload.nonce)
                                });
                            });
                        });
                        this.setState({ 
                            token: tokenResponse.data.clientToken, 
                            loading: false, 
                            error: false,
                            plan, price 
                        })
                    } else {
                        this.setState({ 
                            loading: false, 
                            token: tokenResponse.data.clientToken, 
                            useExistingPayment: true, 
                            error: false,
                            plan, price
                        });
                    }
                } else {
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({loading: false, success: false, error: 'Something went wrong', modalLoading: false, showConfirmationModal: false })
            })
    }

    subscribe(nonce) {
        const { plan, price } = this.state;

        this.setState({
            loading: true,
            modalLoading: true
        })

        axios.post(urls.API_BASE + '/checkouts/subscribe', { nonce: nonce, userId: this.props.userId, plan, clientPrice: price })
            .then((res) => {
                console.log('success');
                this.props.signin(res.data.jwt);
                this.setState({
                    loading: false, 
                    success: true, 
                    error: false, 
                    disableUpgradeBtn: true,
                    receipt: res.data.subscription,
                    modalLoading: false,
                    showConfirmationModal: false,
                })
            })
            .catch(() => {
                this.setState({loading: false, success: false, error: 'Something went wrong', modalLoading: false, showConfirmationModal: false })
            })
    }

    handleProceedClick = async () => {
        this.subscribe();
    }

    handleUpgradeClick = () => {
        this.setState({ showConfirmationModal: true }) 
        // this.subscribe(null);
    }

    render() {
        if (!this.props.location.state) this.props.location.state = {}
        const { loading, success, useExistingPayment, error, disableUpgradeBtn, receipt, showConfirmationModal, modalLoading, plan, price, alreadySubscribed } = this.state;
        let planPrice = price;

        if (parseFloat(price)) {
            planPrice = '$' + price;
        }

        return (
            <div className={container}>
                <List list={[{ title: "Plan", value: plan }, { title: "Price", value: planPrice }]} />
                <br />
                <p className={info}>
                    {" "}
                    <strong>CHARGE INFRORMATION:</strong>
                    <br />
                    {!alreadySubscribed && <span>After three months, 
                    You will be charged ${price} monthly. </span>}

                    {alreadySubscribed && <p className="text-danger">You already have a subscription to the this plan. There is nothing else you need to do at the moment.</p>}
                </p>
                <div id="dropin-container" ref={this.dropinRef}></div>
                {useExistingPayment && <div className={upgradeWrapper}>
                        <button className={upgradeBtn + ' ' + (disableUpgradeBtn ? disabledBtn : '')} onClick={this.handleUpgradeClick}>Upgrade Plan</button>
                    </div>}
                {loading && <div className={circularProgress + ' py-3'}>
                        <CircularProgress color="primary" classes={{ colorPrimary: "grey" }} />
                </div>}
                {success && <Success oneLine={true} message={useExistingPayment ? "Congratulations! your plan has been upgraded successfully" : "Congratulations! you have subscribed successfully"} />}
                {success && <div className={receiptWrapper}>
                        <h5>Transaction Details</h5>
                        <p><b>Balance:</b> ${receipt.balance}</p>
                        <p><b>Monthly Billing Amount:</b> ${receipt.price}</p>
                        <p><b>Billing Start Date:</b> {receipt.firstBillingDate}</p>
                        <p><b>Transaction Date:</b> {new Date(receipt.createdAt).toLocaleString()}</p>
                    </div>}
                {error && <Err message={error} oneLine={false} />}
                <p className={notice}>
                    <strong> NOTE:</strong> If you are not satisfied with your subscription you cancel it anytime by contacting us.
                </p>
                {showConfirmationModal && <div className={confirmationContainer}>
                    <div className={modalWrapper}>
                        {modalLoading && <div className={circularProgress + ' w-100'}>
                                <LinearProgress color="primary" classes={{ colorPrimary: "grey" }} />
                        </div>}
                        <div className={confirmationWrapper}>
                            <div>
                                <h5>Subscription Confirmation</h5>
                                <p>Please confirm your subscription by clicking the proceed button below. </p><br/>
                                <p style={{ fontSize: 14 }}>The new subscription amount will continue to debit out of your payment method currently on record.</p>
                            </div>
                            <div>
                                <button onClick={() => this.setState({ showConfirmationModal: false })} className={cancelBtn + ' ' + ' btn btn-warning mr-2 rounded-0'}>Cancel</button>
                                <button onClick={this.handleProceedClick} className={` btn btn-success rounded-0 ${modalLoading && 'disabled'}`} disabled={modalLoading}>Proceed</button>
                            </div>
                        </div>
                    </div>
                    </div>}
            </div>
        );
    }
}

const mapState = state => {
    return {
        userId: state.auth.userId,
        signedin: state.auth.signedin
    }
}
const mapDispatch = {
    signin,
}

export default withLoggedIn(connect(mapState, mapDispatch)(Subscribe));
