import React, { useEffect, lazy, Suspense } from "react";
import "./App.css";

import { withRouter } from "react-router-dom";

import Header from "./sharedComponents/header";
import BackgroundBanner from "./sharedComponents/backgroundBanner";
import Main from "./components/main/";
import Login from "./components/login/login";
import Footer from "./sharedComponents/footer";
import Layout from "./sharedComponents/layout";
import Verify from "./components/login/verify";
// import Merch from './components/dashboard/merch/';
import Plans from "./components/plans/";
import Subscribe from "./components/plans/subscribe";
import Info from "./components/info/index.jsx";
// import Articles from "./components/articles/Articles.js";
import Contactus from "./components/contactus/";
import Feedback from "./components/common/feedback";
import PageLoading from "./components/common/loadingPage/loadingPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Dashboard from "./components/dashboards";

import "./styles/reset.scss";
import "./styles/shared/index.scss";
// import Account from './components/dashboard/account';
import SignupSuccessView from "./components/login/signupSuccess.view";
import ResetPassword from "./components/login/resetPassword";
import IntegrityTesting from "./components/integrityTesting/integrityTesting";
import Introduction from "./components/static-pages/introduction";
import Faq from "./components/static-pages/faq";

const Dashboard = lazy(() => import('./components/dashboards'));

function App(props) {
    const { pathname } = props.location;

    return (
        <div className="App">
            {pathname.includes("/dashboard") ? (
                <Suspense fallback={<PageLoading />}>
                    <Switch>
                        <Route path="/dashboard/:vendor/:section?" component={Dashboard} />
                    </Switch>
                </Suspense>

            ) : (
                <>
                    <Header />
                    <BackgroundBanner />
                    <Layout>
                        <Switch>
                            <Route path="/contactus" component={Contactus} />
                            <Route path="/reset-password" component={ResetPassword} />
                            <Route path="/introduction" component={Introduction} />
                            <Route path="/faq" component={Faq} />
                            <Route path="/info" component={Info} />
                            {/* <Route path="/articles" component={Articles} /> */}
                            <Route path="/login" component={Login} />
                            <Route path="/integrity" component={IntegrityTesting} />
                            <Route path="/plans" exact component={Plans} />
                            <Route
                                path="/subscribe"
                                exact
                                render={(props) => <Subscribe {...props} />}
                            />
                            <Route
                                path="/subscribe/success"
                                exact
                                render={(props) => (
                                    <SignupSuccessView
                                        message="you have successfully upgraded your account!"
                                        {...props}
                                    />
                                )}
                            />
                            <Route path="/user/verify" component={Verify} />
                            {/* <Route path='/user/merch' exact component={Merch} /> */}
                            {/* <Route path='/user/account' exact component={Account} /> */}
                            <Route path="/dashboard/:vendor" component={Dashboard} />
                            <Route path="/" exact component={Main} />
                        </Switch>
                    </Layout>
                </>
            )}

            <Footer />
            <Feedback />
        </div>
    );
}

export default withRouter(App);
