import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

let circleStyle = {
    width: "300px",
    height: "300px",
    margin: "15px auto"
};

let linearStyle = {
    height: "4px",
    width: "100%"
};
export default props => {
    const { line } = props;
    if (line) {
        return (
            <div {...props} style={linearStyle}>
                <LinearProgress style={{ color: "#da7c08" }} />
            </div>
        );
    } else {
        return (
            <div {...props} style={circleStyle}>
                <CircularProgress style={{ color: "#da7c08" }} />
            </div>
        );
    }
};
