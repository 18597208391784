import {useState, useEffect} from 'react';

export const useElementMaxHeight = (ref, show) => {
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        if(show) {
            let newMaxHeight = ref.current.clientHeight;
            setMaxHeight(newMaxHeight)
        } else {
            setMaxHeight(0);
        }
    }, [ref, show]);

    return maxHeight;
}
