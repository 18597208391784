import React from 'react';
import Loader from 'react-loader-spinner'

import "./loadingPage.scss";

const loadingPage = () => {
    return (
        <div id="loading_page">
            <Loader
                type="Grid"
                color="#00BFFF"
                height={150}
                width={150}        
            />
        </div>
    )
}

export default loadingPage
