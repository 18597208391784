import React, { Component } from 'react';
import { connect } from 'react-redux';

const withLoggedIn = (ProtectedComponent) => {
    class LoggedInOnly extends Component {
        componentDidMount() {
            if (!this.props.loggedin) { 
                this.props.history.push('/login', { redirect: this.props.location.pathname || '/',
                        plan: this.props.location.state ? this.props.location.state.plan : '', 
                        price: this.props.location.state ? this.props.location.state.price : '' });
            }
        }

        componentDidUpdate() {
            if (!this.props.loggedin) { 
                this.props.history.push('/login', { redirect: this.props.location.pathname || '/',
                        plan: this.props.location.state ? this.props.location.state.plan : '', 
                        price: this.props.location.state ? this.props.location.state.price : '' });
            }
        }
        render() {
            return (
                <ProtectedComponent {...this.props} />
            )
        }
    }
    const mapState = (state) => {
        return {
            loggedin: state.auth.signedin,
        }
    }
    return connect(mapState)(LoggedInOnly);
} 

export default withLoggedIn;

