import jwt from 'jsonwebtoken';
import { userPlanEnums } from './constants';

export function sortMerch(merch) {
    const critical = [];
    const unknown = [];
    const safe = [];

    merch.forEach(item => {
        if (item.tracked === false) return unknown.push(item);
        if (item.status === true) return safe.push(item);
        if (item.status === false) return critical.push(item);
        if (item.status === null) return unknown.push(item);
    });

    return [...critical, ...safe, ...unknown];
}

export function capitalizeFirstLetter([firstLetter, ...rest]) {
    return [firstLetter.toLocaleUpperCase(), ...rest].join('');
}

/**
 * 
 * @param {String} mark mark number 
 * @param {Object} merch merch object 
 */
export function markIsIgnored(mark, merch) {
    return false;
}

export function getTrackedMerch(merch, productType) {
    if (productType) {
        productType = parseInt(productType);
    }

    if(Array.isArray(merch)) {
        return merch.filter(item => {
            if (item.tracked || item.track) {
                if (productType || productType === 0) {
                    return item.product_type === productType
                } else {
                    return true;
                }

            }
        });
    } else {
        console.error('argument passed to getNumberOfTrackedMerch must be an array')
        return [];
    }
}
export function getNumberOfTrackedMerch(merch) {
    return getTrackedMerch(merch).length;
}
export function getNumberOfIgnoredMerch(merch) {
    if(Array.isArray(merch)) {
        return merch.length - merch.filter(item => item.tracked).length;
    } else {
        console.error('argument passed to getNumberOfTrackedMerch must be an array')
        return 0;
    }
}
export function getNumberOfUnsafeMerch(merch) {
    if(Array.isArray(merch)) {
        return merch.filter(item => { 
            return item.status === false && item.statusStr !== "WARN" && item.tracked
        }).length;
    } else {
        console.error('argument passed to getNumberOfTrackedMerch must be an array')
        return 0;
    }
}

export function sortByWordCount(words) {
    if (!words || !Array.isArray(words)) return words

    return words.sort((a, b) => {
        if(a.markWord) return a.markWord.split(' ').length - b.markWord.split(' ').length;

        if(typeof a === 'string') return a.split(' ').length - b.split(' ').length
    })
}

export function deduplicate(list) {
    let set = new Set();
    let result = [];
    list.forEach(item => {
        if (!set.has(item)) {
            set.add(item);
            result.push(item);
        }
    })

    return result;
}

/**
 * 
 * @param {string} name 
 * @param {string} [url] href
 */
export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getDecodedJwtState(token) {
    if (!token) return {};

    let decodedToken = jwt.decode(token);
    console.log(decodedToken);
    return {
        signedin: true,
        error: false,
        token: token,
        accountType: decodedToken.accountType,
        userId: decodedToken.id,
        email: decodedToken.email,
        name: decodedToken.name,
        username: decodedToken.username,
        state: decodedToken.state,
        phone: decodedToken.phone,
        plan: decodedToken.plan,
        merchLimit: decodedToken.merchLimit,
        inTrail: decodedToken.inTrail,
        signup_dt: decodedToken.signup_dt
    };
}

export function hasPrivilege(minPrivilege, user) {
    let userPlan = 'FREE';
  
    if (typeof user === 'object') {
      userPlan = user.plan || 'FREE';
    } else if (typeof user === 'string') {
      userPlan = user;
    }
    
    if (!userPlanEnums[userPlan]) return false;
  
    if (userPlanEnums[userPlan] >= minPrivilege) return true;
    return false;
  }

export function appendQuery(url, key, value) {
    let lastSegment = url.split("/");
    lastSegment = lastSegment[lastSegment.length - 1];

    if (!lastSegment.includes('?')) {
        url += '?';
    } else {
        url += '&';
    }

    url+= key + '=' + value;

    return url;
}