import axios from "axios";
import { logout } from '../redux/actions';

axios.defaults.headers.common["x-jwt"] = localStorage.getItem("jwt");
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response?.status && window.location.pathname !== '/login') {
            logout()().then(res => {
                window.location = '/login';
            })
    } else {
        return Promise.reject(error);
    }
});

export default axios;
