export const merchFilters = {
    UNSAFE: 1,
    TRACKED: 2,
    IGNORED: 3,
    NEW_ALERTS: 4
} 

export const searchTypesEnum = {
    "EXACT": 0,
    "SPLIT": 1,
    "CONTAINEDPLUS": 2,
    "STEMMED": 3,
    "SIMILARITY": 4,
}

export const userPlanEnums = {
    FREE: 1,
    BASIC: 2,
    STANDARD: 3,
    PRO: 4,
    CUSTOM: 10
}

export const statusCodes = {
    // LIVE 
    '630': 'NEW',
    '410': 'LIVE',
    '413': 'LIVE',
    '616': 'LIVE',
    '620': 'LIVE',
    '624': 'LIVE',
    '625': 'LIVE',
    '631': 'LIVE',
    '638': 'LIVE',
    '640': 'LIVE',
    '641': 'LIVE',
    '642': 'LIVE',
    '643': 'LIVE',
    '644': 'LIVE',
    '645': 'LIVE',
    '646': 'LIVE',
    '647': 'LIVE',
    '648': 'LIVE',
    '649': 'LIVE',
    '650': 'LIVE',
    '651': 'LIVE',
    '652': 'LIVE',
    '653': 'LIVE',
    '654': 'LIVE',
    '655': 'LIVE',
    '656': 'LIVE',
    '657': 'LIVE',
    '658': 'LIVE',
    '659': 'LIVE',
    '660': 'LIVE',
    '661': 'LIVE',
    '663': 'LIVE',
    '664': 'LIVE',
    '665': 'LIVE',
    '666': 'LIVE',
    '667': 'LIVE',
    '668': 'LIVE',
    '672': 'LIVE',
    '680': 'LIVE',
    '681': 'LIVE',
    '682': 'LIVE',
    '686': 'OPPOSITION',
    '688': 'LIVE',
    '689': 'LIVE',
    '690': 'LIVE',
    '692': 'LIVE',
    '693': 'LIVE',
    '694': 'LIVE',
    '700': 'LIVE',
    '701': 'LIVE',
    '702': 'LIVE',
    '703': 'LIVE',
    '704': 'LIVE',
    '705': 'LIVE',
    '706': 'LIVE',
    '707': 'LIVE',
    '708': 'LIVE',
    '717': 'LIVE',
    '718': 'LIVE',
    '719': 'LIVE',
    '720': 'LIVE',
    '721': 'LIVE',
    '722': 'LIVE',
    '724': 'LIVE',
    '725': 'LIVE',
    '730': 'LIVE',
    '731': 'LIVE',
    '732': 'LIVE',
    '733': 'LIVE',
    '734': 'LIVE',
    '739': 'LIVE',
    '740': 'LIVE',
    '744': 'LIVE',
    '745': 'LIVE',
    '746': 'LIVE',
    '748': 'LIVE',
    '752': 'LIVE',
    '753': 'LIVE',
    '756': 'LIVE',
    '757': 'LIVE',
    '760': 'LIVE',
    '762': 'LIVE',
    '763': 'LIVE',
    '764': 'LIVE',
    '765': 'LIVE',
    '766': 'LIVE',
    '771': 'LIVE',
    '772': 'LIVE',
    '773': 'LIVE',
    '774': 'LIVE',
    '775': 'LIVE',
    '777': 'LIVE',
    '778': 'LIVE',
    '779': 'LIVE',
    '780': 'LIVE',
    '790': 'LIVE',
    '794': 'LIVE',
    '800': 'RENEWED',
    '801': 'LIVE',
    '802': 'LIVE',
    '803': 'LIVE',
    '804': 'LIVE',
    '806': 'LIVE',
    '807': 'LIVE',
    '808': 'LIVE',
    '809': 'LIVE',
    '810': 'LIVE',
    '811': 'LIVE',
    '812': 'LIVE',
    '813': 'LIVE',
    '814': 'LIVE',
    '815': 'LIVE',
    '816': 'LIVE',
    '817': 'LIVE',
    '818': 'LIVE',
    '819': 'LIVE',
    '820': 'LIVE',
    '821': 'LIVE',
    '822': 'LIVE',
    '823': 'LIVE',
    '824': 'LIVE',
    '825': 'LIVE',
    '969': 'LIVE',
    '973': 'LIVE',

    // INDIFFERENT
    '622': 'INDEFFERENT',
    '970': 'INDEFFERENT',

    // DEAD CODES
    '600': 'DEAD', 
    '601': 'DEAD', 
    '602': 'DEAD', 
    '603': 'DEAD', 
    '604': 'DEAD', 
    '605': 'DEAD', 
    '606': 'DEAD', 
    '607': 'DEAD', 
    '608': 'DEAD', 
    '609': 'DEAD', 
    '614': 'DEAD', 
    '710': 'DEAD - CANCELLED',
    '711': 'DEAD', 
    '712': 'DEAD', 
    '713': 'DEAD',
    '714': 'DEAD', 
    '715': 'DEAD', 
    '900': 'DEAD - EXPIRED',
    '612': 'DEAD',
    '400': 'DEAD', 
    '401': 'DEAD', 
    '402': 'DEAD',
    '403': 'DEAD', 
    '404': 'DEAD', 
    '405': 'DEAD', 
    '406': 'DEAD', 
    '411': 'DEAD', 
    '412': 'DEAD', 
    '414': 'DEAD', 
    '415': 'DEAD', 
    '416': 'DEAD', 
    '417': 'DEAD', 
    '618': 'DEAD',
    '626': 'DEAD'
}

export const countries = [
    {"value":"AF", "label":"Afghanistan"},
    {"value":"AX", "label":"Åland Islands"},
    {"value":"AL", "label":"Albania"},
    {"value":"DZ", "label":"Algeria"},
    {"value":"AS", "label":"American Samoa"},
    {"value":"AD", "label":"Andorra"},
    {"value":"AO", "label":"Angola"},
    {"value":"AI", "label":"Anguilla"},
    {"value":"AQ", "label":"Antarctica"},
    {"value":"AG", "label":"Antigua and Barbuda"},
    {"value":"AR", "label":"Argentina"},
    {"value":"AM", "label":"Armenia"},
    {"value":"AW", "label":"Aruba"},
    {"value":"AU", "label":"Australia"},
    {"value":"AT", "label":"Austria"},
    {"value":"AZ", "label":"Azerbaijan"},
    {"value":"BS", "label":"Bahamas"},
    {"value":"BH", "label":"Bahrain"},
    {"value":"BD", "label":"Bangladesh"},
    {"value":"BB", "label":"Barbados"},
    {"value":"BY", "label":"Belarus"},
    {"value":"BE", "label":"Belgium"},
    {"value":"BZ", "label":"Belize"},
    {"value":"BJ", "label":"Benin"},
    {"value":"BM", "label":"Bermuda"},
    {"value":"BT", "label":"Bhutan"},
    {"value":"BO", "label":"Bolivia"},
    {"value":"BA", "label":"Bosnia and Herzegovina"},
    {"value":"BW", "label":"Botswana"},
    {"value":"BV", "label":"Bouvet Island"},
    {"value":"BR", "label":"Brazil"},
    {"value":"IO", "label":"British Indian Ocean Territory"},
    {"value":"BN", "label":"Brunei Darussalam"},
    {"value":"BG", "label":"Bulgaria"},
    {"value":"BF", "label":"Burkina Faso"},
    {"value":"BI", "label":"Burundi"},
    {"value":"KH", "label":"Cambodia"},
    {"value":"CM", "label":"Cameroon"},
    {"value":"CA", "label":"Canada"},
    {"value":"CV", "label":"Cape Verde"},
    {"value":"KY", "label":"Cayman Islands"},
    {"value":"CF", "label":"Central African Republic"},
    {"value":"TD", "label":"Chad"},
    {"value":"CL", "label":"Chile"},
    {"value":"CN", "label":"China"},
    {"value":"CX", "label":"Christmas Island"},
    {"value":"CC", "label":"Cocos (Keeling) Islands"},
    {"value":"CO", "label":"Colombia"},
    {"value":"KM", "label":"Comoros"},
    {"value":"CG", "label":"Congo"},
    {"value":"CD", "label":"Congo, The Democratic Republic of the"},
    {"value":"CK", "label":"Cook Islands"},
    {"value":"CR", "label":"Costa Rica"},
    {"value":"CI", "label":"Cote D'Ivoire"},
    {"value":"HR", "label":"Croatia"},
    {"value":"CU", "label":"Cuba"},
    {"value":"CY", "label":"Cyprus"},
    {"value":"CZ", "label":"Czech Republic"},
    {"value":"DK", "label":"Denmark"},
    {"value":"DJ", "label":"Djibouti"},
    {"value":"DM", "label":"Dominica"},
    {"value":"DO", "label":"Dominican Republic"},
    {"value":"EC", "label":"Ecuador"},
    {"value":"EG", "label":"Egypt"},
    {"value":"SV", "label":"El Salvador"},
    {"value":"GQ", "label":"Equatorial Guinea"},
    {"value":"ER", "label":"Eritrea"},
    {"value":"EE", "label":"Estonia"},
    {"value":"ET", "label":"Ethiopia"},
    {"value":"FK", "label":"Falkland Islands (Malvinas)"},
    {"value":"FO", "label":"Faroe Islands"},
    {"value":"FJ", "label":"Fiji"},
    {"value":"FI", "label":"Finland"},
    {"value":"FR", "label":"France"},
    {"value":"GF", "label":"French Guiana"},
    {"value":"PF", "label":"French Polynesia"},
    {"value":"TF", "label":"French Southern Territories"},
    {"value":"GA", "label":"Gabon"},
    {"value":"GM", "label":"Gambia"},
    {"value":"GE", "label":"Georgia"},
    {"value":"DE", "label":"Germany"},
    {"value":"GH", "label":"Ghana"},
    {"value":"GI", "label":"Gibraltar"},
    {"value":"GR", "label":"Greece"},
    {"value":"GL", "label":"Greenland"},
    {"value":"GD", "label":"Grenada"},
    {"value":"GP", "label":"Guadeloupe"},
    {"value":"GU", "label":"Guam"},
    {"value":"GT", "label":"Guatemala"},
    {"value":"GG", "label":"Guernsey"},
    {"value":"GN", "label":"Guinea"},
    {"value":"GW", "label":"Guinea-Bissau"},
    {"value":"GY", "label":"Guyana"},
    {"value":"HT", "label":"Haiti"},
    {"value":"HM", "label":"Heard Island and Mcdonald Islands"},
    {"value":"VA", "label":"Holy See (Vatican City State)"},
    {"value":"HN", "label":"Honduras"},
    {"value":"HK", "label":"Hong Kong"},
    {"value":"HU", "label":"Hungary"},
    {"value":"IS", "label":"Iceland"},
    {"value":"IN", "label":"India"},
    {"value":"ID", "label":"Indonesia"},
    {"value":"IR", "label":"Iran, Islamic Republic Of"},
    {"value":"IQ", "label":"Iraq"},
    {"value":"IE", "label":"Ireland"},
    {"value":"IM", "label":"Isle of Man"},
    {"value":"IL", "label":"Israel"},
    {"value":"IT", "label":"Italy"},
    {"value":"JM", "label":"Jamaica"},
    {"value":"JP", "label":"Japan"},
    {"value":"JE", "label":"Jersey"},
    {"value":"JO", "label":"Jordan"},
    {"value":"KZ", "label":"Kazakhstan"},
    {"value":"KE", "label":"Kenya"},
    {"value":"KI", "label":"Kiribati"},
    {"value":"KP", "label":"Korea, Democratic People's Republic of"},
    {"value":"KR", "label":"Korea, Republic of"},
    {"value":"KW", "label":"Kuwait"},
    {"value":"KG", "label":"Kyrgyzstan"},
    {"value":"LA", "label":"Lao People's Democratic Republic"},
    {"value":"LV", "label":"Latvia"},
    {"value":"LB", "label":"Lebanon"},
    {"value":"LS", "label":"Lesotho"},
    {"value":"LR", "label":"Liberia"},
    {"value":"LY", "label":"Libyan Arab Jamahiriya"},
    {"value":"LI", "label":"Liechtenstein"},
    {"value":"LT", "label":"Lithuania"},
    {"value":"LU", "label":"Luxembourg"},
    {"value":"MO", "label":"Macao"},
    {"value":"MK", "label":"Macedonia, The Former Yugoslav Republic of"},
    {"value":"MG", "label":"Madagascar"},
    {"value":"MW", "label":"Malawi"},
    {"value":"MY", "label":"Malaysia"},
    {"value":"MV", "label":"Maldives"},
    {"value":"ML", "label":"Mali"},
    {"value":"MT", "label":"Malta"},
    {"value":"MH", "label":"Marshall Islands"},
    {"value":"MQ", "label":"Martinique"},
    {"value":"MR", "label":"Mauritania"},
    {"value":"MU", "label":"Mauritius"},
    {"value":"YT", "label":"Mayotte"},
    {"value":"MX", "label":"Mexico"},
    {"value":"FM", "label":"Micronesia, Federated States of"},
    {"value":"MD", "label":"Moldova, Republic of"},
    {"value":"MC", "label":"Monaco"},
    {"value":"MN", "label":"Mongolia"},
    {"value":"MS", "label":"Montserrat"},
    {"value":"MA", "label":"Morocco"},
    {"value":"MZ", "label":"Mozambique"},
    {"value":"MM", "label":"Myanmar"},
    {"value":"NA", "label":"Namibia"},
    {"value":"NR", "label":"Nauru"},
    {"value":"NP", "label":"Nepal"},
    {"value":"NL", "label":"Netherlands"},
    {"value":"AN", "label":"Netherlands Antilles"},
    {"value":"NC", "label":"New Caledonia"},
    {"value":"NZ", "label":"New Zealand"},
    {"value":"NI", "label":"Nicaragua"},
    {"value":"NE", "label":"Niger"},
    {"value":"NG", "label":"Nigeria"},
    {"value":"NU", "label":"Niue"},
    {"value":"NF", "label":"Norfolk Island"},
    {"value":"MP", "label":"Northern Mariana Islands"},
    {"value":"NO", "label":"Norway"},
    {"value":"OM", "label":"Oman"},
    {"value":"PK", "label":"Pakistan"},
    {"value":"PW", "label":"Palau"},
    {"value":"PS", "label":"Palestine"},
    {"value":"PA", "label":"Panama"},
    {"value":"PG", "label":"Papua New Guinea"},
    {"value":"PY", "label":"Paraguay"},
    {"value":"PE", "label":"Peru"},
    {"value":"PH", "label":"Philippines"},
    {"value":"PN", "label":"Pitcairn"},
    {"value":"PL", "label":"Poland"},
    {"value":"PT", "label":"Portugal"},
    {"value":"PR", "label":"Puerto Rico"},
    {"value":"QA", "label":"Qatar"},
    {"value":"RE", "label":"Reunion"},
    {"value":"RO", "label":"Romania"},
    {"value":"RU", "label":"Russian Federation"},
    {"value":"RW", "label":"Rwanda"},
    {"value":"SH", "label":"Saint Helena"},
    {"value":"KN", "label":"Saint Kitts and Nevis"},
    {"value":"LC", "label":"Saint Lucia"},
    {"value":"PM", "label":"Saint Pierre and Miquelon"},
    {"value":"VC", "label":"Saint Vincent and the Grenadines"},
    {"value":"WS", "label":"Samoa"},
    {"value":"SM", "label":"San Marino"},
    {"value":"ST", "label":"Sao Tome and Principe"},
    {"value":"SA", "label":"Saudi Arabia"},
    {"value":"SN", "label":"Senegal"},
    {"value":"CS", "label":"Serbia and Montenegro"},
    {"value":"SC", "label":"Seychelles"},
    {"value":"SL", "label":"Sierra Leone"},
    {"value":"SG", "label":"Singapore"},
    {"value":"SK", "label":"Slovakia"},
    {"value":"SI", "label":"Slovenia"},
    {"value":"SB", "label":"Solomon Islands"},
    {"value":"SO", "label":"Somalia"},
    {"value":"ZA", "label":"South Africa"},
    {"value":"GS", "label":"South Georgia and the South Sandwich Islands"},
    {"value":"ES", "label":"Spain"},
    {"value":"LK", "label":"Sri Lanka"},
    {"value":"SD", "label":"Sudan"},
    {"value":"SR", "label":"Surilabel"},
    {"value":"SJ", "label":"Svalbard and Jan Mayen"},
    {"value":"SZ", "label":"Swaziland"},
    {"value":"SE", "label":"Sweden"},
    {"value":"CH", "label":"Switzerland"},
    {"value":"SY", "label":"Syrian Arab Republic"},
    {"value":"TW", "label":"Taiwan"},
    {"value":"TJ", "label":"Tajikistan"},
    {"value":"TZ", "label":"Tanzania, United Republic of"},
    {"value":"TH", "label":"Thailand"},
    {"value":"TL", "label":"Timor-Leste"},
    {"value":"TG", "label":"Togo"},
    {"value":"TK", "label":"Tokelau"},
    {"value":"TO", "label":"Tonga"},
    {"value":"TT", "label":"Trinidad and Tobago"},
    {"value":"TN", "label":"Tunisia"},
    {"value":"TR", "label":"Turkey"},
    {"value":"TM", "label":"Turkmenistan"},
    {"value":"TC", "label":"Turks and Caicos Islands"},
    {"value":"TV", "label":"Tuvalu"},
    {"value":"UG", "label":"Uganda"},
    {"value":"UA", "label":"Ukraine"},
    {"value":"AE", "label":"United Arab Emirates"},
    {"value":"GB", "label":"United Kingdom"},
    {"value":"US", "label":"United States"},
    {"value":"UM", "label":"United States Minor Outlying Islands"},
    {"value":"UY", "label":"Uruguay"},
    {"value":"UZ", "label":"Uzbekistan"},
    {"value":"VU", "label":"Vanuatu"},
    {"value":"VE", "label":"Venezuela"},
    {"value":"VN", "label":"Vietnam"},
    {"value":"VG", "label":"Virgin Islands, British"},
    {"value":"VI", "label":"Virgin Islands, U.S."},
    {"value":"WF", "label":"Wallis and Futuna"},
    {"value":"EH", "label":"Western Sahara"},
    {"value":"YE", "label":"Yemen"},
    {"value":"ZM", "label":"Zambia"},
    {"value":"ZW", "label":"Zimbabwe"}
  ]

export const states = [
    ["AL", "Alabama"],
    ["AK", "Alaska"],
    ["AS", "American Samoa"],
    ["AZ", "Arizona"],
    ["AR", "Arkansas"],
    ["CA", "California"],
    ["CO", "Colorado"],
    ["CT", "Connecticut"],
    ["DE", "Delaware"],
    ["DC", "District Of Columbia"],
    ["FM", "Federated States Of Micronesia"],
    ["FL", "Florida"],
    ["GA", "Georgia"],
    ["GU", "Guam"],
    ["HI", "Hawaii"],
    ["ID", "Idaho"],
    ["IL", "Illinois"],
    ["IN", "Indiana"],
    ["IA", "Iowa"],
    ["KS", "Kansas"],
    ["KY", "Kentucky"],
    ["LA", "Louisiana"],
    ["ME", "Maine"],
    ["MH", "Marshall Islands"],
    ["MD", "Maryland"],
    ["MA", "Massachusetts"],
    ["MI", "Michigan"],
    ["MN", "Minnesota"],
    ["MS", "Mississippi"],
    ["MO", "Missouri"],
    ["MT", "Montana"],
    ["NE", "Nebraska"],
    ["NV", "Nevada"],
    ["NH", "New Hampshire"],
    ["NJ", "New Jersey"],
    ["NM", "New Mexico"],
    ["NY", "New York"],
    ["NC", "North Carolina"],
    ["ND", "North Dakota"],
    ["MP", "Northern Mariana Islands"],
    ["OH", "Ohio"],
    ["OK", "Oklahoma"],
    ["OR", "Oregon"],
    ["PW", "Palau"],
    ["PA", "Pennsylvania"],
    ["PR", "Puerto Rico"],
    ["RI", "Rhode Island"],
    ["SC", "South Carolina"],
    ["SD", "South Dakota"],
    ["TN", "Tennessee"],
    ["TX", "Texas"],
    ["UT", "Utah"],
    ["VT", "Vermont"],
    ["VI", "Virgin Islands"],
    ["VA", "Virginia"],
    ["WA", "Washington"],
    ["WV", "West Virginia"],
    ["WI", "Wisconsin"],
    ["WY", "Wyoming"]
]