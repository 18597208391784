export default function handleMobileHover() {
    if (isTouchDevice()) {
        document.querySelectorAll(".trademark-tip__container > a").forEach(el => {
            el.addEventListener('click', e => {
                if (e.target.classList.contains('clicked')) {
                    // default
                } else {
                    e.target.classList.add('clicked');
                    e.preventDefault()
                }
            })
        })
    }
    function isTouchDevice() {
        return 'ontouchstart' in window        // works on most browsers
            || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    };
}