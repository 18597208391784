import React, {useEffect, useState} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import axios from "../../util/axios";
import urls from "../../config/urls";
import ReactPlayer from "react-player"

import {
    details_container,
    main_sentence,
    sentence_part1,
    shape,
    dark,
    fadeIn,
    fadeInLeft,
    example,
    example_results,
    text,
    block,
    listCenter,
    result,
    text_title,
    orange,
    forinstance,
    list,
    detailsCardTitle,
    detailsCard,
    detailsCardLight,
    list_item,
    background_skew_right,
    background_skew_right_dark,
    background_skew_left_dark,
    background_skew_left,
    image,
    image_left
} from "../../styles/main/details.module.scss";
import NumberBlock from "../common/numberBlock/NumberBlock";

export default () => {
    const [testsCount, setTestsCount] = useState({ lastMonth: 0, allTime: 0});

    useEffect(() => {
        let fetchNumbers = async function(){
            let result = await axios.get(urls.API_BASE + '/weekly-integrity-results?count=30&numbersonly=true')
            if (result.status === 200) {
                let data = result.data.data;
                setTestsCount({
                    lastMonth: data.results,
                    allTime: data.totalTests
                })
            } else {
                // TODO
            }
        }
        fetchNumbers();
    }, [])

    return (<div className={details_container}>
        <div className={"py-3 " + block + ' '}>
            <div className={background_skew_right_dark} style={{ transform: 'matrix(1, 0, 0, 1, 0, 0)'}}>
                <div className={shape + ' ' + dark} />
            </div>
            <div className={main_sentence} style={{maxWidth: 1000}}>
                <p className={text}>
                    <span className={sentence_part1}>no more guessing</span>
                    no more terminations, our search algorithem will track every possible adjacent word combination in your amazon merch products!            
            </p>
            {/* <div className={image}> */}
            <div className="d-flex justify-content-center">
                <ReactPlayer url="https://youtu.be/P7ys_upAfNY"  controls={true} light={true}/>
                {/* <img className="rounded-sm" src="/images/main_full.PNG" alt=""/> */}
            </div>
        </div>

            {/* <Link
                style={{
                    fontSize: "28px",
                    cursor: "pointer",
                    display: "block",
                    textDecoration: "underline",
                    color: "#617cce"
                }}
                to="/info"
            >
                More Details
            </Link> */}
        </div>
        <div data-testid="details-section" className={block} style={{overflow: 'hidden'}}>
            <div className="container-lg">
                {/* <div className={background_skew_right}>
                </div> */}
                <div className={`${shape}`} />
                <div className="row d-flex d-flex-wrap flex-wrap-reverse">
                    <div className={"col-md-5 " + detailsCard}>
                        <span className={forinstance}>
                            Tracking This Phrase
                        </span>
                        <span className={example}> Dog moms are awesome!</span>
                        <span className={forinstance}>
                            Will Watch Out For
                        </span>
                        <div className={example_results}>
                            <span className={result}>dog</span>
                            <span className={result}>moms</span>
                            <span className={result}>awesome</span>
                            <span className={result}>dog moms</span>
                            <span className={result}>dog moms are</span>
                            <span className={result}>dog moms are awesome</span>
                            <span className={result}>moms are</span>
                            <span className={result}>moms are awesome</span>
                            <span className={result}>are awesome</span>
                        </div>
                    </div>
                    <div className="col-md-7 pl-4">
                        <h4 className={text_title}>Every Combination</h4>
                        <p className="pb-3">
                            Our tracking tool will track every possible combination
                            of adjacent words in your products against our USPTO database. 
                            There is no need to guess what keywords to track. Just insert your product 
                            description and we take care of the rest. {" "}
                             <Link style={{fontSize: 14}} to="/info/#normalvscontained" >Learn more</Link>
                        </p>
                        <ul className="custom-list custom-list--checks pl-2">
                            <li>Daily updated USPTO database.</li>
                            <li>See exactly how your phrases are split.</li>
                            <li>Add your own ignore rules.</li>
                            <li>Get email alerts.</li>
                        </ul>
                        <br />
                    </div>
                </div>
            </div>
        </div>
        {/* <ScrollAnimation animateIn={fadeInLeft} duration={0.6}>
            <div data-testid="details-section" className={`${block} ${orange}`} style={{ _clipPath: 'polygon(0px 0px, 100% 0%, 100% 100%, 0px 90%)'}}>
                <div className={background_skew_left_dark}>
                    <div className={`${shape} ${dark}`} />
                </div>
                <div className="container-lg">
                    <div className="row d-flex d-flex-wrap">
                        <div className="col-md-7 pr-4">
                            <h4 className={text_title}>Stemmed Search</h4>
                            <p>
                                To make our search safe, we also match trademarks that share the same english root word to your keywords.
                                 
                                For instance, with other trademark search websites, looking up
                                "love living" will not catch "loving living" and other derivatives. Ours, on the other hand, will match it. You can always disable 
                                stemmed option however.
                            </p>
                        </div>
                        <div className={"col-md-5 " + detailsCardLight}>
                            <span className={forinstance}>looking up</span>
                            <span className={example}>love living</span>
                            <span className={forinstance}>will look for the following matches</span>
                            <div className={example_results}>
                                <span className={result}>loving living</span>
                                <span className={result}>love lives</span>
                                <span className={result}>loved living</span>
                                <span className={result}>love living</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollAnimation> */}
        <ScrollAnimation animateIn={fadeIn} duration={0.6}>
            <div data-testid="details-section" className={`${block}`} style={{overflow: 'hidden'}}>
                <div className="container-lg">
                    <div className="row">
                        <div className={background_skew_left}>
                            <div className={`${shape}`} />
                        </div>
                        <div className="col-12">
                            <h4 className={text_title}>Similarity Tracking</h4>
                            <p className="mb-4">
                                Trademark laws consider whether a trademark is similar 
                                enough to cause confusion. We took that into account and developed three mechanisms to cover this scenario: 
                                sound, stemmed and similarity matching.
                            </p>
                        </div>
                        <div className="row d-flex flex-wrap justify-content-around mb-4">
                            <div className={"col-md-6 px-md-4"}>
                                <div className={detailsCard}>
                                    <h4 className={detailsCardTitle}><i className="fas fa-microphone-alt"></i> Sound Matches</h4>
                                    <p>Allows you to find trademarks that sound similar to your phrase. Many times trademarkeds are not spelled correctly 
                                        or they might be a play on words. Those can be caught with a our sound search. 
                                    </p>
                                </div>
                            </div>
                            <div className={"col-md-6 px-md-4"}>
                                <div className={detailsCard} style={{ height: '100%' }}>
                                    <h4 className={detailsCardTitle}><i className="fas fa-wave-square"></i> Similar Matches</h4>
                                    <p>Gives you control in choosing the level of similarity between your product words and newly filed trademarks in 
                                        which you should be alerted by email.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex flex-wrap justify-content-around">
                            <div className={"col-md-6 px-md-4"}>
                                <div className={detailsCard} style={{ height: '100%' }}>
                                    <h4 className={detailsCardTitle}><i className="fas fa-wave-square"></i> Stemmed Matching</h4>
                                    <p>Matches trademarks that share the same english root word to your phrase. For instance, the word 
                                        Love will match Loving though the latter is missing the letter E.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn={fadeInLeft} duration={0.6}>
            <div data-testid="details-section" className={`${block} ${orange}`} style={{ _clipPath: 'polygon(0px 0px, 100% 0%, 100% 100%, 0px 90%)'}}>
                <div className={background_skew_left_dark}>
                    <div className={`${shape} ${dark}`} />
                </div>
                <div className="container-lg">
                    <div className="row d-flex d-flex-wrap">
                        <div className="col-md-7 pr-4">
                            <h4 className={text_title}>Trusted Data</h4>
                            <p className="mb-2">
                                We take the accuracy and integrity of our trademark database very seriously and In order to test and prove this 
                                we developed a tool that compares thousands of trademarks from our database against USPTO data (using their API) on a daily basis.
                            </p>

                            <p className="mb-3">
                                We also give you the ability to run tests yourself as well as see our daily test results. We run two types of daily tests - Randomized and Specific. 
                                {" "} <Link to="/integrity" style={{color: 'lightblue'}}>Click here to learn more.</Link>
                            </p>
                        </div>
                        <div className={"col-md-5 " }>
                           <NumberBlock number={testsCount.lastMonth} text="Trademarks tested last 30 days" theme="style-2" />
                           <div className="mb-2"></div>
                           <NumberBlock number={testsCount.allTime} text="Trademarks all time tested" theme="style-2" />
                        </div>
                    </div>
                </div>
            </div>
        </ScrollAnimation>
        <div data-testid="details-section" className={block} style={{overflow: 'hidden'}}>
            <div className="container-lg">
                {/* <div className={background_skew_right}>
                </div> */}
                <div className={`${shape}`} />
                <div className="row d-flex d-flex-wrap flex-wrap-reverse">
                    <div className={'col-md-5 ' + image + ' ' + image_left}>
                        <img className="shadow" src="/images/newmarks.png" alt=""/>  
                    </div>
                    <div className="col-md-7 pl-4">
                        <h4 className={text_title}>Stay Up To Date</h4>
                        <p className="pb-3">
                             Our tool provides multiple ways to stay updated with Trademark changes. 
                             One of the great features we offer allows you to sort, filter and search any of the new trademarks based on similarity to 
                             your products keywords - rather than you having to inspect thousands of trademarks manually. 
                             We offer the following lists of trademark changes:   {" "}
                             {/* <Link style={{fontSize: 14}} to="/info/#normalvscontained" >Learn more</Link> */}
                        </p>
                        <ul className="custom-list custom-list--checks pl-2">
                            <li>Newly Filed</li>
                            <li>Revived</li>
                            <li>Newly Registered</li>
                            <li>In Opposition</li>
                            <li>Recently Died</li>
                            <li>Soon To be Dead</li>
                        </ul>
                        <br />
                    </div>
                </div>
            </div>
        </div>
        <div data-testid="details-section" className={`${block} ${orange}`}>
            <div className="container-lg">
                 {/* <div className={`${shape} ${dark}`} /> */}
                 <div className={background_skew_right_dark}>
                    <div className={`${shape} ${dark}`} />
                </div>
                <div className="row d-flex d-flex-wrap flex-wrap-reverse">
                    <div className="col-md-7 pr-4">
                        <h4 className={text_title}>Chrome Extension</h4>
                        <p className="pb-3">
                            Get access to our <a style={{color: 'white', textDecoration: 'underline'}} href="https://chrome.google.com/webstore/detail/trademark-search/akfghebhfgjekjfdnkmjmmcgogicijgk">Chrome extension</a> that will allow you to search and track your products with one click while you 
                            are on the products page. This provides a way to track a high number of merch items quickly. Our extension has been tested 
                            and proven to outmatch the competitors in accuracy due to our data accuracy and search algorithms.{" "}
                             {/* <Link style={{fontSize: 14}} to="/info/#normalvscontained" >Learn more</Link> */}
                        </p>
                        <ul className="custom-list custom-list--checks pl-2">
                            <li>Fast Bulk Tracking</li>
                            <li>Quick Trademark Search before publishing</li>
                            <li>Trademark search on live products with one click</li>
                        </ul>
                        <br />
                    </div>
                    <div className={'col-md-5 mb-2 mb-md-0 ' + image + ' ' + image_left}>
                        <img className="shadow" src="/images/merchprotect-extension.png" alt="trademark protection extension"/>  
                    </div>
                </div>
            </div>
        </div>
        {/* <ScrollAnimation animateIn={fadeInLeft} duration={0.6}>
            <div data-testid="details-section" className={`${block}`} style={{ _clipPath: 'polygon(0px 28px, 100% 0%, 100% 100%, 0px 106%)'}}>
                <div className={`${shape}`} />

                <div className="container-lg">
                    <div className="row">
                        <div className="col-12 mb-3 text-center">
                            <h4 className={text_title}>Search Criteria</h4>
                            Every combination is looked up using the following criteria
                            <br />
                        </div>
                        <div className="col-12">
                            <ul className={`${list} ${listCenter}`}>
                                <li className={list_item}>LIVE mark</li>
                                <li className={list_item}>Drawing code equals 4000 or 1000</li>
                                <li className={list_item}>Product relevant International Class</li>
                                <li className={list_item}>For clothing we also include International Class 035 where trademark use statement includes a clothing term</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollAnimation> */}
    </div>
);
}
