import React from "react";
import { Link } from "react-router-dom";
import "./static-pages.scss";

const Introduction = () => {
    return (
        <div id="mp_blog">
            <h1>Merch Protect Introduction</h1>
            <p>
                As you might already know, Amazon is very strict about their trademark rules and in
                order to protect your account you have to insure that all of your product text is trademark
                safe.
            </p>
            <p>
                As MBA sellers ourselves, we were always paranoid every time we click the publish button.
                Common questions we always had were, "Did I look up all combinations of keywords
                during my trademark search?" "What about similar words that do not match exactly?"
                "How can I trust trademark data on other tools?"
            </p>
            <p>
                Our concerns did not end when our designs went live. Hundreds of new trademarks were
                being filed every day. When we were just starting out, we were able to carry out a
                manual review process on a weekly basis but as the number of designs grew, we
                quickly realized how time consuming and unmanageable the trademark process is.
            </p>
            <p>
                We started looking at available tools to help track our products for new trademarks
                but we did not find anything that solved our problem exactly. We found tools to track
                keywords and phrases, but knew we could not predict which keyword combination we
                should track. What if we missed a keyword? What about similarity matches?
            </p>
            <p>
                We did not feel completely protected with the available tools and they were time consuming.
                That is when we realized we needed something created specifically for merch sellers
                like ourselves.
            </p>
            <p>
                Being a software engineer myself, I decided to solve the problem and put my mind at
                ease. That is when I built this tool. The idea was to have a trademark search and
                tracking tool that would do the following:
            </p>
            <ul className="custom-list custom-list__v1">
                <li>
                    <h3>Track Products Not Keywords</h3>
                    <p>
                        In order to be truly protected, we knew we needed to have a tool that would
                        track all the product details and take care of all the keyword combinations.
                        After all, we are human and bound to miss some combination of keywords on
                        our own.
                    </p>
                    <p>
                        In order to be truly protected, we knew we needed to have a tool that would
                        track all the product details and take care of all the keyword combinations.
                        After all, we are human and bound to miss some combination of keywords on
                        our own.
                    </p>
                </li>
                <li>
                    <h3>Catch Similar Matches</h3>
                    <p>
                        Amazon’s algorithms are smart and that’s why we knew we could not depend on
                        exact matches only. So we came up with two mechanisms to handle that:
                    </p>
                    <p>
                        <b>Stemmed Matching:</b> This type of matching will match keywords using
                        their most basic English root. For instance, the words “loving” and “loves”
                        would both be stemmed to “love” and therefore will be treated as a match.
                        For that reason our search engine will match “love living” with “loving
                        living” when no other tool would.
                    </p>
                    <p>
                        <b>Mataphonic Matching:</b> We developed a special algorithm to allow us to
                        find matches that sound similar. This is not bullet proof but could be
                        useful to catch trademarks that are a play on words in cases where two words
                        are combined such as BULLDOG or GIRLDAD. These matches will not be found
                        with a traditional trademark search for Bull Dog or Girl Dad.
                    </p>
                    <p>
                        <b>Similarity Matching: </b> This mechanism is used in our daily monitoring
                        process to match keywords based on how many letters of difference there is
                        between them. You set the maximum difference threshold before an alert is
                        triggered. For instance, take the trademark “HOMME”. This word requires one
                        letter edit to become “HOME”. If you have a maximum threshold of 1 or above
                        then this trademark will be included in your weekly alert email. You can
                        expect non-alerting matches but this can be essential in some cases.
                    </p>
                </li>
                <li>
                    <h3>Have Trusted Data</h3>
                    <p>
                        Having a trademark database that we can trust with our merch account was our
                        number one priority. There is really no reason to use our tool if we can’t
                        trust its integrity. That is why we have spent months developing
                        separate software that has one purpose only: testing our database integrity.
                        Creating this testing program helped us find all the inconsistencies and
                        perfect our database. When compared to other tools, we can confidently say
                        that ours outperforms all the ones we have tested against whether with
                        accuracy or up-to-date data. This testing program is run on a daily basis
                        twice: (1) to run random tests and (2) to test our daily uploaded data.
                    </p>
                    <p>
                        Only when we were confident with our database did we decide to depend on
                        this tool and make it public through our website. In fact you can see our
                        test results for the last 30 days{" "}
                        <Link to="/integrity">run your own integrity tests</Link>.
                    </p>
                </li>
                <li>
                    <h3>Stay Up to Date</h3>
                    <p>
                        We wanted to stay updated with the latest trademark changes such as those
                        newly registered, newly filed, revived and other types of trademark updates.
                        That is why we created the alerting system. Two emails will be sent each
                        week with the alerting matches.
                    </p>
                    <p>
                        We also wanted to have a simple way to view and filter new trademark
                        updates. There could be anywhere from a 1000 to 3000+ newly filed or
                        registered trademarks each week. Who wants to go through all of them and try
                        to figure out if their products have a similar keyword?
                    </p>
                    <p>
                        That is why we add the Trademarks update section on our website, which
                        allows you to view different types of trademark updates (e.g. newly filed,
                        newly registered, etc.). The value in this section, however, is in our merch
                        similarity sorting feature. Rather than going through each trademark, you
                        can sort the trademarks list based on similarity percentages to your own
                        products. This will run a process that compares each trademark on the list
                        with each of your products and then highlights the similar match for you to
                        check. Depending on the number of products you have, this might take a few
                        seconds but it’s still much faster than having to do it manually.
                    </p>
                </li>
            </ul>
            <p>
                <h2>Chrome Extension</h2>
            </p>
            <p>
                For convenience and speed, we knew we needed a way to search and track products from
                the Amazon page itself, especially on the publishing page. That is why we created
                our own extension. It has made our lives much easier and we are sure you will love
                it. Expect some small issues in our beta release but updates are coming to make it
                run more smoothly.
            </p>

            <p>
                <h2>Final Thoughts</h2>
            </p>
            <p>
                This tool was originally used for internal purposes but we knew many others would
                find it helpful and that is why we decided to make it available to everyone. Today
                we are launching our beta version and expect our official release to beat the
                beginning of January. You can sign up today and start using it but do expect to
                encounter minor issues and please report them We are happy to fix them in a timely
                manner.
            </p>
            <p>
                We are ready to listen to community suggestions and add/improve features as needed.
                We offer this tool in good faith and we hope you find it useful. For instruction on
                how to use this tool, please check out the video below and for more details on our
                feature, please check out our <Link to="/info">information page</Link>.
            </p>
        </div>
    );
};

export default Introduction;
