import React from "react";
import { Link } from "react-router-dom";
import ExpandableTab from "../info/expandableTab";
import "./static-pages.scss";

const Faq = () => {
    return (
        <div id="mp_blog">
            <h1>FAQ</h1>
            {/* <ExpandableTab title="When can I subscribe to your paid plans?">
                <p className="mb-3">
                    Our paid plans will be available when we do our Beta launch very soon. Currently you can signup for our free plan and get familiar with our tool. 
                </p>
            </ExpandableTab> */}
            <ExpandableTab title="Can I trust your trademark data?">
                <p className="mb-3">
                    Our data comes directly from the USPTO daily uploaded xml files and we update our database on a daily bases. To insure our database integrity 
                    we run extensive daily tests. You can read more and see the daily test result on our 
                    <Link to="/integrity"> integrity testing page</Link>. 
                </p>
            </ExpandableTab>
            <ExpandableTab title="When is your trademark database updated?">
                <p className="mb-3">
                    Our trademark database is updated on a daily basis at around 2:00am CDT. You can see the date for the latest data that our database contains on the top 
                    of the search box on our main page. you will usually notice that the date displayed is two days prior to today and that is because every update we run  pulls 
                    the latest from two days prior.
                </p>
            </ExpandableTab>
            <ExpandableTab title="If I use your chrome extension, will you have access to my account?">
                <p className="mb-3">
                   No. When you use our extension we will not have access to your merch by amazon account. The extension is in the browser only and no information will be saved without 
                   your consent. The only time we save data for a given product is when you click on the track button. 
                </p>
            </ExpandableTab>
            <ExpandableTab title="Is my data shared with anyone?">
                <p className="mb-3">
                   Your data will never be shared with anyone or for any purpose outside of the services we offer you. We understand the hard work you have put into building your business and we assure you that no other sellers will have access to your data. Please refer to our <a target="_blank" href="https://api.merchprotect.com/privacy-policy.html">privacy policy</a> for more details.
                </p>
            </ExpandableTab>
            <ExpandableTab title="Can I cancel my subscription at any time?">
                <p className="mb-3">
                   Yes, you are able to cancel at any time by sending us an email at support@merchprotect.com.
                </p>
            </ExpandableTab>
        </div>
    )
}

export default Faq;