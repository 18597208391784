import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reducer from './redux/reducers';
import thunk from 'redux-thunk';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';
import { getDecodedJwtState } from './util';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, 
  { auth: getAuthFromJwt()}, composeEnhancers(
  applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
      <Router>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </Provider>
  ,document.getElementById('root'));


function getAuthFromJwt() {
    let auth= {};
    let storedJwt = localStorage.getItem('jwt');
    if (!storedJwt) return false;
    let decoded = jwt.decode(storedJwt);
    let now = Date.now().valueOf() / 1000
    if(!decoded || (!decoded.exp || decoded.exp < now)) {
        auth.signedin = false
    } else {
        const decodedState = getDecodedJwtState(storedJwt);
        auth = {...decodedState}
    }

    return auth;
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
