import {
    ADD_NEWMERCH,
    MERCH_SUBMIT_ERROR,
    NEWMERCH_LOADING,
    TRACK,
    PRODUCT_TRACKING_ERROR,
    UNTRACK,
    FETCH_MERCH_ERROR,
    DELETE_MERCH,
    TRACK_LOADING,
    IGNORE_MARK,
    TRACK_MARK,
    POPULATE_MERCH,
    MERCH_LOADING,
    SAVE_IGNOREWORDS_LOADING,
    SAVE_IGNOREWORDS_ERROR,
    SAVE_IGNOREWORDS_SUCCESS,
    SAVE_IGNOREPHRASES_LOADING,
    SAVE_IGNOREPHRASES_ERROR,
    SAVE_IGNOREPHRASES_SUCCESS,
    UPDATE_WEEKLY_NEWTRADEMARKS,
    SET_MERCH_WITH_COMBS,
    LOGOUT
} from "../types";
import { sortMerch } from "../../util";
import { searchTypesEnum } from "../../util/constants";

let initialState = {
    merch: [],
    // merch: [{
    //     title: 'test only tshirt',
    //     description1: 'this is an awesome tshirt',
    //     description2: 'buy this tshirt today as a gift for your loved ones',
    //     status: false,
    //     tracked: true,
    //     serial_no: '51',
    //     alerts: [{ mark_word: 'ME TOO', mark_id: '7589456' }]
    //    loading: false,
    // }],
    alerts: null,
    alertsNotSeen: 0,
    error: false,
    loading: false,
    success: null,
    fetchError: false,
    weeklyIndexedData: [],
    merchWithCombs: [],
    totalPages: 1,
    pageNumber: 1,
    totalTracked: 0,
    totalDanger: 0,
    totalNotSeen: 0,
    errors: {
        trackingError: false,
    },
    settings: {
        ignoreWordsLoading: false,
        ignoreWordsError: false,
        ignoreWords: [],
    }
};

export default (state = initialState, action) => {
    const merch = [...state.merch];

    switch (action.type) {
        case ADD_NEWMERCH:
            return {
                ...state,
                error: false,
                loading: false,
                success: true,
                fetchError: false,
                merch: Array.isArray(action.payload)
                    ? [...sortMerch(action.payload), ...state.merch]
                    : [action.payload, ...state.merch],
                empty: false
            };
        case MERCH_LOADING: 
            return {
                ...state,
                loading: true,
            }
        case LOGOUT: 
            return {
                ...initialState
            }
        case POPULATE_MERCH:
            return {
                ...state,
                error: false,
                loading: false,
                success: true,
                fetchError: false,
                merch: Array.isArray(action.payload.merch) ? [...sortMerch(action.payload.merch)] : [action.payload.merch],
                alerts: extractAlerts(action.payload.merch || []),
                alertsNotSeen: getAlertsNotSeen(action.payload.merch || []),
                alertingMerch: getMerchWithAlertsNotSeen(action.payload.merch || []),
                totalPages: action.payload.totalPages,
                pageNumber: action.payload.pageNumber,
                totalTracked: action.payload.totalTracked,
                totalDanger: action.payload.totalDanger,
                totalNotSeen: action.payload.totalNotSeen,
                empty: action.payload.merch.length === 0 ? true : false
            };
        case NEWMERCH_LOADING:
            return {
                ...state,
                success: null,
                loading: true,
            };
        case MERCH_SUBMIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload.response?.data ? action.payload.response.data : action.payload.message,
            };
        case TRACK_LOADING:
            merch.forEach(item => {
                if (item.serial_no === action.payload) {
                    item.loading = true;
                }
            });
            return {
                ...state,
                merch
            };
        case TRACK:
            const { id, status, statusStr, alerts, ignoredCommonWords, all_adjacent_possible_combs } = action.payload;

            merch.forEach(item => {
                if (item.serial_no === id) {
                    item.tracked = true;
                    item.status = status;
                    item.statusStr = statusStr;
                    item.alerts = alerts;
                    item.loading = false;
                    item.trackingError = false;
                    item.ignore_common_words = ignoredCommonWords;
                    item.all_adjacent_possible_combs = all_adjacent_possible_combs;
                }
            });
            return {
                ...state,
                merch,
                alerts
            };
        case PRODUCT_TRACKING_ERROR:
            merch.forEach(item => {
                if (item.serial_no === action.payload.id) {
                    item.trackingError =
                        "unable to track/untrack: " + action.payload.message ||
                        "something went wrong while tracking";
                    state.errors.trackingError = true;
                    item.loading = false;
                }
            });
            return {
                ...state,
                merch
            };
        case UNTRACK:
            merch.forEach(item => {
                if (item.serial_no === action.payload) {
                    item.tracked = false;
                    item.status = null;
                    item.statusStr = 'UNKNOWN'
                }
            });
            return {
                ...state,
                merch
            };
        case FETCH_MERCH_ERROR:
            return {
                ...state,
                loading: false,
                fetchError: action.payload.message
            };
        case DELETE_MERCH:
            merch.forEach(item => {
                if (item.serial_no == action.payload) {
                    item.show = false;
                }
            });
            return {
                ...state,
                merch
            };
        case IGNORE_MARK:
            merch.forEach(item => {
                if (item.serial_no === action.payload.merchId) {
                    let alert = item.alerts.find(alert => alert.mark_id === action.payload.markId);
                    alert.ignored = true
                    item.status = action.payload.status;
                    item.statusStr = action.payload.statusStr;

                    if (action.payload.delete) {
                        item.hide = true;
                    }
                }
            });
            return {
                ...state,
                merch
            };
        case TRACK_MARK:
            merch.forEach(item => {
                if (item.serial_no === action.payload.merchId) {
                    let alert = item.alerts.find(alert => alert.mark_id === action.payload.markId);
                    alert.ignored = false
                    item.status = action.payload.status;
                    item.statusStr = action.payload.statusStr;
                }
            });
            return {
                ...state,
                merch
            };
        case SAVE_IGNOREWORDS_LOADING:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ignoreWordsLoading: true,
                }
            }
        case SAVE_IGNOREWORDS_SUCCESS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ignoreWordsLoading: false,
                    ignoreWordsError: false,
                    ignoreWords: action.payload,
                }
            }
        case SAVE_IGNOREWORDS_ERROR:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ignoreWordsLoading: false,
                    ignoreWordsError: action.payload.message || 'failed to save ignore words, please try again.',
                }
            }
        case SAVE_IGNOREPHRASES_LOADING:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ignorePhrasesLoading: true,
                }
            }
        case SAVE_IGNOREPHRASES_SUCCESS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ignorePhrasesLoading: false,
                    ignorePhrasesError: false,
                    ignorePhrases: action.payload || [],
                }
            }
        case SAVE_IGNOREPHRASES_ERROR:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ignorePhrasesLoading: false,
                    ignorePhrasesError: action.payload.message || 'failed to save ignore words, please try again.',
                }
            }
        case UPDATE_WEEKLY_NEWTRADEMARKS:
            return {
                ...state,
                weeklyIndexedData: action.payload
            }
        case SET_MERCH_WITH_COMBS:
            return {
                ...state,
                merchWithCombs: action.payload,
            }
        default:
            return state;
    }
};

function extractAlerts(merch) {
    let alerts = [];

    merch.forEach(item => {
        if (!item.alerts?.length) return;

        alerts = [...alerts, ...item.alerts]
    });

    return alerts;
}

function getAlertsNotSeen(merch) {
    let alertsNotSeen = [];

    merch.forEach(item => {
        if (!item.alerts?.length) return;

        item.alerts.forEach(alert => {
            if (!alert.seen && !alert.ignored) alertsNotSeen.push(alert);
        })
    });

    return alertsNotSeen;
}
function getMerchWithAlertsNotSeen(merch) {
    let alertsNotSeen = [];

    merch.forEach(item => {
        if (!item.alerts?.length) return;
        let pushed = false

        item.alerts.forEach(alert => {
            if (!pushed && !alert.seen && !alert.ignored && alert.match_type !== searchTypesEnum.SIMILARITY) {
                alertsNotSeen.push(item);
                pushed = true;
            }
        })
    });

    return alertsNotSeen;
}