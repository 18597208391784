import React from "react";
import {
    successWrapper,
    title,
    successMessage,
    successWrapperOneLine
} from "../styles/success.module.scss";

export default ({ message, oneLine }) => {
    if (oneLine) {
        return (
            <div className={successWrapperOneLine}>
                <p className={successMessage}>{message}</p>
            </div>
        );
    }
    return (
        <div className={successWrapper}>
            <p className={title}>Success</p>
            <p className={successMessage}>{message}</p>
        </div>
    );
};
