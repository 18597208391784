import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import { useElementMaxHeight } from '../shared_hooks/useElementMaxHeight';

import './index.scss'
import { Link } from 'react-router-dom';

Explanation.propTypes = {
    message: PropTypes.string,
    show: PropTypes.bool,
}

Explanation.defaultProps = {
    message: 'Sorry, no message to display',
    show: false,
}

function Explanation({ message, show }) {
    const ref = useRef();
    const maxHeight = useElementMaxHeight(ref, show);


    return <div id="explanation" className={`explanation__container mb-2 ${show && "explanation__container--show"}`} style={{ maxHeight }}>
        <div ref={ref} className="explanation__wrapper">
            <p className="explanation__text" dangerouslySetInnerHTML={{__html: message}}>
            </p>
        </div>
    </div>
}

export default Explanation;