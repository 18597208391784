import React, { useState, useEffect, useRef } from 'react';
import { LinearProgress } from "@material-ui/core";
import axios from '../../../util/axios';
import html2canvas from 'html2canvas';
import Err from '../../../sharedComponents/error';
import urls from '../../../config/urls';

import "./feedback.scss";

export default function Feedback() {
    const containerRef = useRef();
    const [form, setForm] = useState({message: '', email: ''});
    const [positionRight, setPositionRight] = useState(-400);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        let rootListener = document.getElementById('root').addEventListener('click', e => {
            if (!containerRef.current.contains(e.target)) {
                setOpen(false);
            }
        })
        setPositionRight(-containerRef.current.offsetWidth);

        return () => {
            document.removeEventListener('click', rootListener);
        }
    }, [])
    useEffect(() => {
        let width =  open ? 0 : -containerRef.current.offsetWidth;
        setPositionRight(width);
        setShowSuccess(false);
    }, [open])

    let changeForm = e => {
        console.log(e.target.id);        
        if (e.target.value && e.target.value.length > 1000) {
            e.target.value = e.target.value.slice(0, 1000);
        }

        setError(false);
        setForm({
            ...form,
            [e.target.id]: e.target.value
        })
    }
    let submitForm = async (e) => {
        e.preventDefault();
        if(!form.message) return setError('Details field can\'t be empty');
        // TODO add botton to allow user to take a screenshot
        // let screenshotCanvas = await html2canvas(document.getElementById('root'));
        // console.dir(screenshotCanvas);
        form.path = window.location.pathname;

        try {
            setError(false);
            setLoading(true);
            let res = await axios.post(urls.API_BASE + '/feedback', form);
            if(res.status === 200 && res.data.success === true) {
                setShowSuccess(true)
                setLoading(false);
            } else {
                throw res;
            }
        } catch(e) {
            setLoading(false);
            setError(e.message + '. Please email us at info@merchprotect.com')
        }
    }
    return (
        <div id="feedback" style={{right: positionRight}} ref={containerRef}>
            {showSuccess && <div style={{textAlign: 'center', color: '#419541', padding: '20px 0px'}}>
                <p className="feedback__exit" onClick={() => setOpen(false)}>x</p>
                <div>
                    <i className="far fa-check-circle fa-5x"></i>
                </div><br/>
                <p>Reported Successfully</p>
                <p>We will review this Issue and contact you shortly.</p>
            </div>}
            {!showSuccess && <div>
                    {error && <Err message={error} oneLine={true}/>}
                    <p className="feedback__exit" onClick={() => setOpen(false)}>x</p>
                    <p className="feedback__title">Report an Issue</p>
                    <p className="feedback__subtitle">use this form to report bugs or to provide a feedback</p>
                    <form onSubmit={submitForm} onChange={e => changeForm(e)}>
                        <label htmlFor="message">Details</label>
                        <textarea name="message" id="message" value={form.message} cols="30" rows="7" placeholder="provide a detailed description in order to resolve quickly.."></textarea>
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" maxLength="100" value={form.email}/>
                        <button>Submit {loading && <LinearProgress />}</button>
                    </form>
                </div>
            }
            <p className="feedback__toggle" onClick={() => setOpen(!open)}>
                {/* <i className="fas fa-bug" style={{fontSize: 15}}></i>{' '} */}
                Report Issue
            </p>
        </div>)
}
