import React from "react";
import { mark } from "../styles/questionMark.module.scss";

export default ({ message, width, iconWidth }) => {
    const style = {};
    const iconStyle = {};

    if (width) style.width = width;
    if (iconWidth) iconStyle.width = iconWidth;
    if (iconWidth) iconStyle.height = iconWidth;

    return (
        <div className={mark}>
            <p style={style} dangerouslySetInnerHTML={{ __html: message}}></p>
        </div>
    );
};
