import React from "react";
import { Link } from "react-router-dom";

let style = {
    color: "grey",
    width: "fit-content",
    margin: "50px auto",
    fontSize: "22px",
    fontWeight: "bold",
    fontFamily: "Helvetica, Arial"
};

export default () => (
    <h3 style={style}>
        Your account has been verified! now head over to the <Link to="/">main page</Link>{" "}
    </h3>
);
