import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import axios from "axios";
import {
    searchbox_container,
    title,
    title_container,
    subTitle,
    textArea,
    submitBtn,
    form,
    combsNumber,
    spinner,
    settingContainer,
    settingBtn,
    settingForm,
    searchSection,
    settingContainerRow,
    settingLabel,
    openForm,
    note,
    tmSymbol
} from "../../styles/main/searchbox.module.scss";

import Results from "./results.jsx";
import Spinner from "@bit/bondz.react-epic-spinners.semipolar-spinner";
import ErrorBar from "../../sharedComponents/errorBar";

import findCombCount from "../../util/findCombCount";
import {sortByWordCount, getParameterByName} from "../../util/index";
import Explanation from "../common/explanation";
import urls from "../../config/urls";

class SearchBox extends Component {
    state = {
        text: "",
        loading: false,
        results: null,
        metaphoneMatches: null,
        commonWordsMatches: null,
        allWordsIncludedMatches: null,
        combsCount: null,
        showStats: false,
        estimateTime: null,
        error: false,
        errMessage: "",
        openSettings: false,
        looseSearch: "true",
        strictSearch: "false",
        showContainedExplanation: false,
        showSearchTypesExplanation: false,
        showClass35Exp: false,
        showProductTypeExplanation: false,
        showSoundExplanation: false,
        showSplitExplanation: false,
        allPossibleAdjacentWordCombs: null,
        ignoredCommonWords: null,
        searchType: 0,
        includeDead: false,
        metaphone: true,
        contained: true,
        containedPlus: false,
        registered: false,
        split: true,
        stemmed: false,
        class35: true,
        searchTypes: ['exact', 'split', 'containedplus', 'stemmed'],
        productTypes: ['cloth', 'popsocket', "phonecases", 'totebags', 'throwpillows'],
        productType: 0,
        updatedOn: null,

    };

    componentDidMount() {
        const searchQuery = getParameterByName('search');

        if (searchQuery) this.setState({ text: searchQuery }, () => {
            this.handleSubmit();
        })

        // console.log(searchQuery);
        axios.get(urls.API_BASE + '/search/db-update-time/')
            .then(res => {
                this.setState({
                    updatedOn: res.data.process_dt
                })
            })
    }

    handleChange = e => {
        this.setState({
            text: e.target.value
        });
    };

    handleOpenSettings = () => {
        this.setState({
            openSettings: !this.state.openSettings
        });
    };

    handleSettingChange = e => {
        // let searchTypes = ['exact', 'split', 'contained', 'containedplus'];
        if (e.target.id === 'productType') {
            const { productTypes } = this.state;
            this.setState({
                productType: productTypes.indexOf(e.target.value)
            })
        } else {
            const { searchTypes } = this.state;
            let newState = {
                searchType: searchTypes.indexOf(e.target.value),
            }
            this.setState(newState);
        }
    };
    
    handleContainedChange = e => {
        let value = e.target.value;
        // console.log(value);
        if (value === 'contained') {
            this.setState({ contained: true, containedPlus: false })
        } else if (value === 'containedPlus') {
            this.setState({ contained: true, containedPlus: true })
        } else {
            this.setState({ contained: false, containedPlus: false })
        }
    }

    /**
     * 
     * @param {Event} [e] 
     */
    handleSubmit = async e => {
        if (e) e.preventDefault();
        if (this.state.text.trim() === "") return;
        let count = findCombCount(this.state.text);
        let estimateTime = count / 20;
        this.setState({ loading: true, combsCount: count, estimateTime }, async () => {
            try {
                let res = await axios.post(
                    process.env.NODE_ENV !== "production"
                        ? `search/sentence?searchtype=${this.state.searchType}&includedead=${this.state.includeDead}&sound=${this.state.metaphone}&contained=${this.state.contained}`
                        : `https://api.merchprotect.com/search/sentence?searchtype=${this.state.searchType}&includedead=${this.state.includeDead}&sound=${this.state.metaphone}&contained=${this.state.contained}`,
                    { 
                        text: this.state.text,
                        split: this.state.split,
                        class35: this.state.class35,
                        contained: this.state.contained,
                        registered: this.state.registered,
                        containedPlus: this.state.containedPlus,
                        productType: this.state.productType,
                    }
                );
                if(res.status === 200) {
                    let state = {
                        loading: false,
                        showStats: true,
                        error: false,
                        results: res.data.matchesFound || [],
                        allWordsIncludedMatches: res.data.containsAllMatches,
                        metaphoneMatches: res.data.metaphoneMatches,
                        allPossibleAdjacentWordCombs: res.data.allPossibleAdjacentWordCombs,
                        ignoredCommonWords: res.data.ignoredCommonWords,
                        commonWordsMatches: res.data.commonWordsMatches
                    }

                    if (Array.isArray(res.data.commonWordsMatches)) state.results = [...state.results, ...res.data.commonWordsMatches];
                    state.results.sort((a, b) => a.markWord.length - b.markWord.length);
                    this.setState(state);
                } else {
                    throw new Error(res.status +': Something went wrong, please try again.');
                }
            } catch (err) {
                console.log(err);

                this.setState({
                    loading: false,
                    error: true,
                    errMessage:
                        err.response && err.response.data
                            ? err.response.data
                            : err.message
                            ? err.message
                            : "something went wrong"
                });
            }
        });
    };

    render() {
        const {
            text,
            loading,
            results,
            metaphoneMatches,
            commonWordsMatches,
            allWordsIncludedMatches,
            combsCount,
            showStats,
            estimateTime,
            error,
            errMessage,
            openSettings,
            looseSearch,
            strictSearch,
            showContainedExplanation,
            showSearchTypesExplanation,
            showClass35Exp,
            showProductTypeExplanation,
            showSoundExplanation,
            allPossibleAdjacentWordCombs,
            ignoredCommonWords,
            searchTypes,
            searchType,
            metaphone,
            includeDead,
            contained,
            containedPlus,
            split,
            stemmed,
            class35,
            registered,
            updatedOn,
            showSplitExplanation,
            productTypes,
            productType
        } = this.state;

        // if (results?.length) {
        //     let print = results.map(result => result.serial_no).join(', ');
        //     // console.log(print);
        // }
        // if (commonWordsMatches?.length) {
        //     let print = commonWordsMatches.map(result => result.serial_no).join(', ');
        //     // console.log(print);
        // }

        return (
            <div className={searchbox_container}>
                {/* <div className="px-2 mb-5">
                    <div className="p-3 px-4 w-100 mx-auto shadow" style={{ maxWidth: 675, fontWeight: 400, backgroundColor: '#fff4d3' }}>
                        <p className="lead" style={{ color: '#9a6400', fontSize: 16}}>
                            This tool is in the Beta stage! Read our <a href="https://bit.ly/3kNHV6m" target="_blank">launch blog</a> and <Link to="/plans">subscribe to our free plan</Link>.  
                        </p>
                    </div>
                </div> */}
                <div className="pt-5"></div>
                <div className={title_container}>
                    <h1 className={title}>MERCH PROTECT<span className={tmSymbol}>&trade;</span></h1>
                    <p className={subTitle + ' lead'}>Trademark search and monitoring for Merch By Amazon sellers made easy!</p>
                </div>
                <div className={searchSection}>
                    {updatedOn && <p className={note}>Data updated on {new Date(updatedOn).toLocaleDateString()}</p>}
                    <form className={form} onSubmit={this.handleSubmit}>
                        <textarea
                            type="text"
                            name="textArea"
                            id="textArea"
                            className={textArea}
                            value={text}
                            maxLength={256}
                            placeholder="enter phrase here.."
                            onChange={this.handleChange}
                        />
                        <input type="submit" value="GO" className={submitBtn} />
                    </form>
                    <div className={settingContainer}>
                        <div className={settingContainerRow}>
                            <div
                                className={`${
                                    true || openSettings || ( results && results.length) ? settingForm + " " + openForm : settingForm
                                    }`}
                                >
                                    <div
                                    className={`${
                                        true || openSettings || ( results && results.length) ? settingForm + " " + openForm : settingForm
                                    }`}
                                >
                                <div className="select_wrapper w-100 mr-2">
                                    <select id="productType" className="select" value={productTypes[productType]} onChange={this.handleSettingChange}>
                                        <option id="cloth" value="cloth" >025 (Clothing)</option>
                                        <option id="popsocket" value="popsocket" >009 (Popsockets/phone cases)</option>
                                        <option id="totebags" value="totebags" >018 (Tote Bags)</option>
                                        <option id="throwpillows" value="throwpillows" >020 (Throw Pillows)</option>
                                    </select>
                                </div>
                                {/* <i onClick={() => this.setState({showProductTypeExplanation: !showProductTypeExplanation})} className="far fa-question-circle" style={{paddingRight: 5, paddingLeft: 5, color: '#00000085', cursor: 'pointer'}}></i> */}
                                {productType === 0 && <>
                                        <label style={{minWidth: 50}} htmlFor="class35" className={`d-flex align-items-center mr-0 custom-checkbox-container ${settingLabel}`}>
                                             035
                                            <input disabled={containedPlus} className="" type="checkbox" name="class35" id="class35" checked={class35} onChange={() => this.setState({class35: !this.state.class35})}/>
                                            {/* <label htmlFor="contained" className={settingLabel}>CONTAINED </label>    */}
                                            <span className="checkmark"></span>
                                        </label>      
                                        <i onClick={() => this.setState({showClass35Exp: !showClass35Exp})} className="far fa-question-circle" style={{paddingRight: 5, paddingLeft: 5, color: '#00000085', cursor: 'pointer'}}></i>
                                    </>
                                }
                                {/* <div className="select_wrapper w-100">
                                    <select id="searchType" className="select" value={searchTypes[searchType]} onChange={this.handleSettingChange}>
                                        <option id="exact" value="exact" >Exact</option>
                                        <option id="stemmed" value="stemmed" >Stemmed</option>
                                    </select>
                                </div>
                                <i onClick={() => this.setState({showSearchTypesExplanation: !showSearchTypesExplanation})} className="far fa-question-circle" style={{paddingRight: 5, paddingLeft: 5, color: '#00000085', cursor: 'pointer'}}></i> */}
                            </div>
                            </div>
                            {/* <span className={settingBtn} onClick={this.handleOpenSettings}>
                                settings
                            </span> */}
                        </div>
                        <div className={settingContainerRow} style={{ visibility: true || openSettings || ( results && results.length) ? 'visible' : 'hidden', borderBottom: '1px solid #0000001a'}}>
                            <div className="d-flex align-items-center flex-wrap">
                                <div htmlFor="contained" className={`d-flex align-items-center mb-1 ml-0 ${settingLabel}`}>
                                    <div className="select_wrapper">
                                        <select name="contained" id="contained" className="select" 
                                            value={containedPlus ? 'containedPlus' : contained ? 'contained' : ''} 
                                            style={{width: 140}} 
                                            onChange={this.handleContainedChange}>
                                            <option id="notContained" value="" >NOT CONTAINED</option>
                                            <option value="contained">CONTAINED</option>
                                            <option value="containedPlus">CONTAINED (each)</option>
                                        </select>
                                    </div>
                                    <i onClick={() => this.setState({showContainedExplanation: !showContainedExplanation})} className="far fa-question-circle" style={{paddingRight: 0, paddingLeft: 4, fontSize: 12, color: '#00000085', cursor: 'pointer'}} ></i>
                                </div>
                                <label htmlFor="split" className={`d-flex align-items-center mb-1 ml-2 custom-checkbox-container ${settingLabel}`}>
                                    SPLIT
                                    <input disabled={containedPlus} className="" type="checkbox" name="split" id="split" checked={split} onChange={() => this.setState({split: !this.state.split})}/>
                                    {/* <label htmlFor="contained" className={settingLabel}>CONTAINED </label>    */}
                                    <span className="checkmark"></span>
                                </label>                                
                                <label htmlFor="metaphone"  className={`d-flex align-items-center mb-1 ml-2  custom-checkbox-container ${settingLabel}`}>
                                    SOUND
                                    <input className="ml-1" type="checkbox" name="metaphone" id="metaphone" checked={metaphone} onChange={() => this.setState({metaphone: !this.state.metaphone})}/>
                                    <span className="checkmark"></span>
                                </label>
                                <i onClick={() => this.setState({showSoundExplanation: !showSoundExplanation})} className="far fa-question-circle" style={{paddingRight: 0, paddingLeft: 4, fontSize: 12, color: '#00000085', cursor: 'pointer'}} ></i>
                                <label htmlFor="stemmed" className={`d-flex align-items-center mb-1 ml-2 custom-checkbox-container ${settingLabel}`}>
                                    STEMMED
                                    <input disabled={containedPlus} className="" type="checkbox" name="stemmed" id="stemmed" checked={searchType === 3} onChange={() => this.setState({searchType: searchType === 3 ? 0 : 3})}/>
                                    <span className="checkmark"></span>
                                </label>                                
                                <i onClick={() => this.setState({showSearchTypesExplanation: !showSearchTypesExplanation})} className="far fa-question-circle" style={{paddingRight: 0, paddingLeft: 4, fontSize: 12, color: '#00000085', cursor: 'pointer'}}></i>
                                <label htmlFor="Dead" className={`d-flex align-items-center mb-1 ml-2 custom-checkbox-container ${settingLabel}`}>
                                    DEAD
                                    <input className="ml-1" type="checkbox" name="Dead" id="Dead" checked={includeDead} onChange={() => this.setState({includeDead: !this.state.includeDead})}/>
                                    {/* <label htmlFor="Dead" className={"form-check-label " + settingLabel}>DEAD</label>   */}
                                    <span className="checkmark"></span>
                                </label>
                                <label htmlFor="registered" title="Only show registered trademarks" className={`d-flex align-items-center mb-1 custom-checkbox-container ${settingLabel}`}>
                                    REGIST.
                                    <input disabled={containedPlus} className="" type="checkbox" name="registered" id="registered" checked={registered} onChange={() => this.setState({registered: !this.state.registered})}/>
                                    <span className="checkmark"></span>
                                    {/* <i onClick={e => this.setState({showSplitExplanation: !showSplitExplanation})} className="far fa-question-circle" style={{paddingRight: 0, paddingLeft: 4, fontSize: 12, color: '#00000085', cursor: 'pointer'}} ></i> */}
                                </label>
                                
                            </div>
                        </div>
                    </div>
                    <div className="pt-1">
                        <Explanation 
                                message="
                                    <p style='margin-bottom: 5px'><b style='font-weight: bold'>Stemmed:</b><br></p>
                                    <p style='margin-bottom: 5px'>Will find stemmed matches. These are trademarks that share the same english root with your keywords even thought they might not be an exact match. 
                                        This might delay results a little but could be helpful. 
                                    </p>
                                " 
                                
                                show={showSearchTypesExplanation}
                        />
                        <Explanation 
                                message="
                                    <p style='margin-bottom: 5px'><b style='font-weight: bold'>Include 035 Class:</b><br></p>
                                    <p>
                                        For clothing, class 035 (Advertising and Business Services) can be problematic when clothing is claimed in the goods and services. 
                                        Checking this box will include trademarks that are registered under class 035 only if they include one of the following words or their derivatives in the goods and services: <br>
                                        <b style='font-weight: bold'> cloth | shirt | sweatshirt | sweater | t-shirt | hoodie | tank top </b>
                                    </p>
                                " 
                                
                                show={showClass35Exp}
                        />
                        <Explanation 
                                message="                            
                                <p style='margin-bottom: 5px'><b style='font-weight: bold'>CONTAINED:</b></p>
                                <p>If selected, our engine will match trademarks that are longer than your sentence and contain it.</p>
                                <br>
                                <p style='margin-bottom: 5px'><b style='font-weight: bold'>CONTAINED (each)</b></p>
                                <p>This will apply CONTAINED on each split combination. Selecting this option might return many results but it could be helpful when extreme caution is necessary. </p>
                                <a href='/info/#normalvscontained'>Learn more</a>" 
                                
                                show={showContainedExplanation}
                        />
                        <Explanation 
                                message="                            
                                <p style='margin-bottom: 5px'><b style='font-weight: bold'>SOUND:</b></p>
                                <p>If selected, our engine will match trademarks that are similar in sound to your phrase and/or have a few letters difference.</p>
                                <a href='/info/#normalvscontained'>Learn more</a>" 
                                
                                show={showSoundExplanation}
                        />
                        <Explanation 
                                message={`                            
                                <p style='margin-bottom: 5px'><b style='font-weight: bold'>SPLIT:</b></p>
                                <p>If selected, your phrase will be split into every possible combination. In order to see these combinations, click on processed N keyword combinations</p>
                                <a href='/info/#normalvscontained'>Learn more</a>
                                `}
                                
                                show={showSplitExplanation}
                        />
                        <Explanation 
                                message={`                            
                                <p style='margin-bottom: 5px'><b style='font-weight: bold'>Product Types</b></p>
                                <p style='margin-bottom: 5px'><b style='font-weight: bold'>Clothing:</b> Matches class 025</p>
                                <p style='margin-bottom: 5px'><b style='font-weight: bold'>Popsocket:</b> Matches class 009 which also includes phone cases.</p>
                                <p style='margin-bottom: 5px'><b style='font-weight: bold'>Tote Bags:</b> Matches class 009 which also includes phone cases.</p>
                                <p style='margin-bottom: 5px'><b style='font-weight: bold'>Popsocket:</b> Matches class 009 which also includes phone cases.</p>
                                <a href='/info/#normalvscontained'>Learn more</a>
                                `}
                                
                                show={showProductTypeExplanation}
                        />
                    </div>
                </div>
                {loading ? (
                    <>
                        <Spinner color="#b9b9b9" size={75} className={spinner} />
                        <p className={combsNumber}>
                            Looking up{" "}
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                                {combsCount}
                            </span>{" "}
                            combination
                        </p>
                    </>
                ) : error ? (
                    <ErrorBar message={errMessage} title="Server Error" />
                ) :
                 (<>
                    {<Results 
                        combsCount={combsCount}
                        showStats={showStats} 
                        results={results} 
                        allPossibleAdjacentWordCombs={allPossibleAdjacentWordCombs}
                        ignoredCommonWords={ignoredCommonWords}   
                        searchType={searchType}
                        searchPhrase={text}
                        rowsTitle={searchTypes[searchType] + ' Results'}
                        titleIconclassName="fas fa-check-square"
                    />}
                    
                    {/* {<Results 
                            combsCount={combsCount} 
                            results={commonWordsMatches} 
                            searchType={searchType}
                            rowsTitle="Common Words Results"
                            titleIconclassName="fas fa-leaf"
                            style={{opacity: 0.8}}
                            titleStyle={{color: '#8bae82'}}
                            searchPhrase={text} 
                            secondaryList={true}
                        />} */}
                    {<Results 
                            combsCount={combsCount} 
                            results={sortByWordCount(metaphoneMatches)} 
                            allPossibleAdjacentWordCombs={allPossibleAdjacentWordCombs}
                            ignoredCommonWords={ignoredCommonWords}   
                            searchType={searchType}
                            rowsTitle="Sound Matches"
                            titleIconclassName="fas fa-microphone-alt"
                            searchPhrase={text} 
                            secondaryList={true}
                        />}
                    {<Results 
                            combsCount={combsCount} 
                            results={allWordsIncludedMatches} 
                            allPossibleAdjacentWordCombs={allPossibleAdjacentWordCombs}
                            ignoredCommonWords={ignoredCommonWords}   
                            searchType={searchType}
                            rowsTitle="Order Ignored"
                            titleIconclassName="fas fa-random"
                            searchPhrase={text} 
                            secondaryList={true}
                        />}
                    
                    </>
                )}
            </div>
        );
    }
}

export default withRouter(SearchBox);